import React from "react";
import JoditEditor from "jodit-pro-react";
import { withStyles } from "@material-ui/core/styles";
import ReactHtmlParser from 'react-html-parser';

const styles = () => ({
  root: {
    "& .jodit-container": {
      border: "none !important"
    },
    "& .jodit-wysiwyg": {
      padding: "0px !important"
    },
    marginTop: "1rem"
  }
});

class RawText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {}
    };
  }

  componentDidMount() {
    const { data } = this.props;

    this.setState({
      initialData: data.data
    });
  }

  render() {
    const { data, classes, isAnnotationModeOn } = this.props;

    return (
      !isAnnotationModeOn ? <div className={classes.root}>
        <JoditEditor
          value={this.state.initialData !== data.data ? data.data : this.state.initialData}
          config={{
            readonly: true,
            disablePlugins: "resizer",
            useIframeResizer: false,
            toolbar: false,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            minHeight: "unset"
          }}
          tabIndex={1} 
        />
      </div>
      : ReactHtmlParser(this.state.initialData !== data.data ? data.data : this.state.initialData)
    );
  }
}

export default withStyles(styles)(RawText);
