import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/core/styles";
import { getColourFromString } from "./Util";
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';

// Constants
const MIN_ITEMS_MESSAGE = 'Minimum {} items required.';
const MAX_ITEMS_MESSAGE = 'Maximum {} items allowed.';
const CURRENT_ITEMS_MESSAGE = 'Current: {}';
const ENTER_BETWEEN_MESSAGE = 'Enter between {} and {} values. ' + CURRENT_ITEMS_MESSAGE;
const ENTER_AT_LEAST_MESSAGE = 'Enter at least {} value{}. ' + CURRENT_ITEMS_MESSAGE;
const ENTER_MAX_MESSAGE = 'Enter a maximum of {} value{}. ' + CURRENT_ITEMS_MESSAGE;
const DOUBLE_CLICK_PLACEHOLDER = "Double-click to edit the list";
const TYPE_ENTER_PLACEHOLDER = "Type something and press enter to add a list item";
const COMMA_SEPARATED_HELPER = "Enter values separated by a comma";

// Styles
const styles = theme => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  annotationListInput: {
    border: "1px solid #d5d5d5",
    padding: "8px 16px 5px",
    borderRadius: "4px",
    fontSize: "14px",
    marginTop: "5px",
    minHeight: "40px",
    overflowWrap: "break-word"
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  helperText: {
    fontSize: '11px',
    color: '#999999',
    marginTop: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    fontSize: '16px',
    marginRight: '4px',
    color: theme.palette.info.main,
  },
  constraintMessage: {
    fontSize: '11px',
    marginTop: '4px',
  }
});

// Styled components
const Chip = styled("div")(({ colour }) => ({
  border: `1px solid ${colour.border}`,
  backgroundColor: colour.bg,
  borderRadius: "4px",
  width: "fit-content",
  padding: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "10px",
}));

const CancelButton = styled(CancelIcon)({
  cursor: 'pointer',
  fontSize: '16px',
  '&:hover': {
    opacity: 0.7,
  },
});

class ListInput extends Component {
  state = {
    answer: [],
    isEditing: false,
    inputValue: '',
    errorMessage: '',
    isReadOnly: false
  };

  componentDidMount() {
    this.initializeAnswer();
    this.setState({ isReadOnly: this.props.data.read_only });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.data !== this.props.data.data) {
      this.updateAnswerFromProps();
    }
    if (prevProps.data.read_only !== this.props.data.read_only) {
      this.setState({ isReadOnly: this.props.data.read_only });
    }
  }

  // Parse string to list
  parseStringToList = str => {
    return str ? str.replace(/[\[\]']+/g, "").split(",").map(item => item.trim()).filter(Boolean) : [];
  };

  // Initialize answer from form data or props
  initializeAnswer = () => {
    const { form, data } = this.props;
    const answerData = form.answer_data[data.key];
    
    if (answerData && answerData.answer) {
      // Filter out the 'list' prefix and empty strings
      this.setState({ 
        answer: answerData.answer
          .filter((item, index) => index !== 0 || item !== 'list')
          .filter(Boolean)
      });
    } else {
      this.updateAnswerFromProps();
    }
  };

  // Update answer from props
  updateAnswerFromProps = () => {
    const { data } = this.props;
    let answer = Array.isArray(data.data) ? data.data : this.parseStringToList(data.data);
    
    if (!answer.length && data.auto) {
      answer = Array.isArray(data.auto) ? data.auto : this.parseStringToList(data.auto);
    }

    // Filter out the 'list' prefix and empty strings
    answer = answer.filter((item, index) => index !== 0 || item !== 'list').filter(Boolean);

    this.setState({ answer }, () => {
      if (answer.length) {
        this.updateParent();
      }
    });
  };

  // Handle adding a chip
  handleOnAdd = (chip) => {
    if (this.state.isReadOnly) return;

    const { data } = this.props;
    const { answer } = this.state;
    const max = parseInt(data.list_max, 10);

    if (max && answer.length >= max) {
      this.setState({ errorMessage: MAX_ITEMS_MESSAGE.replace('{}', max) });
      return;
    }

    const newItems = chip.split(',').map(item => item.trim()).filter(Boolean);
    
    this.setState(prevState => ({
      answer: [...prevState.answer, ...newItems],
      errorMessage: ''
    }), this.updateParent);
  };

  // Handle deleting a chip
  handleDeleteChip = index => {
    const { data } = this.props;
    const { isReadOnly } = this.state;
    if (isReadOnly) return;
    const min = parseInt(data.list_min, 10);

    this.setState(prevState => {
      const newAnswer = prevState.answer.filter((_, i) => i !== index);
      const errorMessage = min && newAnswer.length < min ? MIN_ITEMS_MESSAGE.replace('{}', min) : '';
      return { answer: newAnswer, errorMessage };
    }, this.updateParent);
  };

  // Handle clearing all chips
  handleClearAll = () => {
    this.setState({ answer: [] }, this.updateParent);
  };

  // Update parent component with answer data
  updateParent = () => {
    const { getDataFromComponent, data } = this.props;
    const { answer } = this.state;
    const min = parseInt(data.list_min, 10);
    
    // Only update parent if minimum requirement is met
    if (!min || answer.length >= min) {
      getDataFromComponent({ list_input: answer }, data.key, data.question_text, data.data);
    }
  };

  // Convert array to list string
  convertToListString = arr => `[${arr.join(", ")}]`;

  // Handle double click to enter editing mode
  handleDoubleClick = () => {
    if (this.state.isReadOnly) return;
    this.setState({ 
      isEditing: true, 
      inputValue: this.state.answer.join(', ') 
    }, () => {
      if (this.inputRef) {
        this.inputRef.focus();
        this.inputRef.setSelectionRange(this.inputRef.value.length, this.inputRef.value.length);
      }
    });
  }

  // Set input ref
  setInputRef = (element) => {
    this.inputRef = element;
  }

  // Handle blur event in editing mode
  handleBlur = () => {
    const { data } = this.props;
    const newAnswer = this.state.inputValue
      .split(',')
      .map(item => item.trim())
      .filter(Boolean);
    const min = parseInt(data.list_min, 10);
    const max = parseInt(data.list_max, 10);

    let errorMessage = '';
    if (min && newAnswer.length < min) {
      errorMessage = MIN_ITEMS_MESSAGE.replace('{}', min);
    } else if (max && newAnswer.length > max) {
      errorMessage = MAX_ITEMS_MESSAGE.replace('{}', max);
    }

    this.setState({ 
      isEditing: false, 
      answer: newAnswer, 
      errorMessage,
      inputValue: '', // Clear the input value
    }, () => {
      this.updateParent();
      if (min && newAnswer.length < min) {
        // If minimum not met, keep editing mode on
        this.setState({ isEditing: true });
      }
    });
  }

  // Handle input change in editing mode
  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  }

  // Handle key down event in editing mode
  handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.handleBlur();
    }
  }

  // Render constraint message
  renderConstraintMessage = () => {
    const { data, classes } = this.props;
    const { answer, errorMessage } = this.state;
    const min = parseInt(data.list_min, 10);
    const max = parseInt(data.list_max, 10);
    let message = '';

    if (errorMessage) {
      message = `${errorMessage} (${CURRENT_ITEMS_MESSAGE.replace('{}', answer.length)})`;
    } else if (min > 0 && max > 0) {
      message = ENTER_BETWEEN_MESSAGE.replace('{}', min).replace('{}', max).replace('{}', answer.length);
    } else if (min > 0) {
      message = ENTER_AT_LEAST_MESSAGE.replace('{}', min).replace('{}', min !== 1 ? 's' : '').replace('{}', answer.length);
    } else if (max > 0) {
      message = ENTER_MAX_MESSAGE.replace('{}', max).replace('{}', max !== 1 ? 's' : '').replace('{}', answer.length);
    }

    const color = errorMessage ? 'error' : 'textSecondary';
    return message ? (
      <Typography variant="caption" color={color} className={classes.constraintMessage}>
        {message}
      </Typography>
    ) : null;
  };

  handleChange = (e, newValue, reason) => {
    if (this.state.isReadOnly) return;

    const actions = {
      "clear": this.handleClearAll,
      "remove-option": () => {
        const lastIndex = this.state.answer.length - 1;
        if (lastIndex >= 0) {
          this.handleDeleteChip(lastIndex);
        }
      },
      "create-option": () => {
        const lastValue = newValue[newValue.length - 1];
        if (lastValue && typeof lastValue === 'string') {
          const newItems = lastValue.split(',').map(item => item.trim()).filter(Boolean);
          newItems.forEach(item => this.handleOnAdd(item));
        }
      }
    };

    const action = actions[reason];
    if (action) {
      action();
    } else if (reason === 'selectOption') {
      // Allow duplicate values
      this.setState(prevState => ({
        answer: [...prevState.answer, ...newValue.filter(item => !prevState.answer.includes(item))]
      }), this.updateParent);
    } else {
      // Update the state with the new value
      this.setState({ answer: newValue }, this.updateParent);
    }
  }
  
  render() {
    const { classes, data, isAnnotationModeOn } = this.props;
    const { answer, isEditing, inputValue, errorMessage, isReadOnly } = this.state;

    // Render annotation mode
    if (isAnnotationModeOn) {
      return (
        <div id={`${data.key}-answer-text`} className={classes.annotationListInput}>
          {this.convertToListString(answer)}
        </div>
      );
    }

    // Render normal mode
    return (
      <div className={classes.root}>
        <Grid direction="column" container>
          {isEditing ? (
            // Render editing mode
            <>
              <TextField
                fullWidth
                variant="outlined"
                value={inputValue}
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKeyDown}
                multiline
                error={!!errorMessage}
                autoFocus
                inputRef={this.setInputRef}
                helperText={errorMessage}
                disabled={isReadOnly}
              />
              <p className={classes.helperText}>
                <InfoIcon className={classes.infoIcon} />
                {COMMA_SEPARATED_HELPER}
              </p>
            </>
          ) : (
            // Render normal mode with Autocomplete
          <Autocomplete
              multiple
              options={[]}
              value={answer}
              freeSolo
              fullWidth
              disabled={isReadOnly}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={answer.length ? DOUBLE_CLICK_PLACEHOLDER : TYPE_ENTER_PLACEHOLDER}
                  multiline
                  error={!!errorMessage}
                  helperText={errorMessage}
                  onDoubleClick={this.handleDoubleClick}
                  disabled={isReadOnly}
                  onBlur={(event) => {
                    if (isReadOnly) return;
                    const newValue = event.target.value.trim();
                    if (newValue) {
                      this.handleOnAdd(newValue);
                    }
                  }}
                />
              )}
              renderTags={(tagValue, getTagProps) => (
                <div className={classes.chipContainer}>
                  {tagValue.map((option, index) => (
                    <Chip
                      key={index}
                      {...getTagProps({ index })}
                      colour={getColourFromString(option)}
                    >
                      <div style={{ wordWrap: "anywhere" }}>{option}</div>
                      <CancelButton
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleDeleteChip(index);
                        }}
                      />
                    </Chip>
                  ))}
                </div>
              )}
              onChange={this.handleChange}
            />
          )}
          {this.renderConstraintMessage()}
        </Grid>
      </div>
    );
  }
}

ListInput.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isAnnotationModeOn: PropTypes.bool.isRequired,
  getDataFromComponent: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  form: state.form
});

export default connect(mapStateToProps)(withStyles(styles)(ListInput));