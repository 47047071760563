/**
 * Validates input value against validation rules (regex or number)
 * @param {string|number} value - The input value to validate
 * @param {object} validation - Validation rules object containing regex pattern or min/max values
 * @param {string} inputType - Type of input ('number' or undefined for text)
 * @returns {boolean} True if validation passes or no validation rules exist
 */
export const validatePattern = (value, validation, inputType) => {
    if (!validation) {
        return true;
    }

    if (validation.regex) {
        return validateRegexPattern(value, validation.regex);
    }

    if (inputType === 'number' && hasNumberValidation(validation)) {
        return validateNumberRange(value, validation);
    }

    return true;
};

/**
 * Gets validation error message based on validation rules
 * @param {string|number} value - The input value to validate
 * @param {object} validation - Validation rules object containing regex pattern or min/max values
 * @param {string} inputType - Type of input ('number' or undefined for text)
 * @returns {string} Error message if validation fails, empty string otherwise
 */
export const getValidationHelperText = (value, validation, inputType) => {
    if (!validation) {
        return "";
    }

    if (!validatePattern(value, validation, inputType)) {

        if (validation.message) {
            return validation.message;
        }

        if (validation.regex) {
            return getRegexErrorMessage(value, validation.regex);
        }

        if (inputType === 'number' && hasNumberValidation(validation)) {
            return getNumberRangeErrorMessage(value, validation);
        }
    }

    return "";
};

const validateRegexPattern = (value, regex) => {
    if (!value) return true;
    return new RegExp(regex).test(value);
};

const validateNumberRange = (value, validation) => {
    if (!value) return true;
    
    // If no min or max defined, the number is valid
    if (!hasNumberValidation(validation)) return true;
    
    const numValue = Number(value);
    if (isNaN(numValue)) return false;
    
    const hasMin = validation.min !== null && validation.min !== undefined && validation.min !== '';
    const hasMax = validation.max !== null && validation.max !== undefined && validation.max !== '';
    
    // Only return false if validation actually fails
    if (hasMin && numValue < validation.min) return false;
    if (hasMax && numValue > validation.max) return false;
    
    return true;
};

const hasNumberValidation = (validation) => {
    // Check if min/max are explicitly set to something other than undefined
    return (validation.min !== undefined && validation.min !== null && validation.min !== '') || 
           (validation.max !== undefined && validation.max !== null && validation.max !== '');
};

const getRegexErrorMessage = (value, regex) => {

    if (isWordBoundaryPattern(regex)) {
        return `Your answer must contain ${extractWordFromPattern(regex)}`;
    }

    if (isContainsPattern(regex)) {
        return `Must contain: ${extractContainsPattern(regex)}`;
    }

    if (isWordBoundaryChoicePattern(regex)) {
        const choices = extractChoicesFromPattern(regex);
        return `Your answer must contain one of: ${choices.join(" or ")}`;
    }

    return `Must match pattern: ${regex}`;

};

const getNumberRangeErrorMessage = (value, validation) => {
    if (!value) return "";
    
    const numValue = Number(value);
    if (isNaN(numValue)) {
        return "Please enter a valid number";
    }

    const hasMin = validation.min !== null && validation.min !== undefined && validation.min !== '';
    const hasMax = validation.max !== null && validation.max !== undefined && validation.max !== '';

    // If no min or max defined, no validation needed
    if (!hasMin && !hasMax) {
        return "";
    }

    // Only show error message if validation actually fails
    if (hasMin && numValue < validation.min) {
        return `Number must be at least ${validation.min}`;
    }
    
    if (hasMax && numValue > validation.max) {
        return `Number must not exceed ${validation.max}`;
    }

    return "";
};

const isWordBoundaryPattern = (regex) => regex.includes('\\b[Aa]');

const extractWordFromPattern = (regex) => regex.replace(/\\b\[Aa\]/, 'A').replace(/\\b/g, '');

const isContainsPattern = (regex) => regex.startsWith('.*') && regex.endsWith('.*');

const extractContainsPattern = (regex) => regex.slice(2, -2);

const isWordBoundaryChoicePattern = (regex) => regex.startsWith('\\b(') && regex.endsWith(')\\b');

const extractChoicesFromPattern = (regex) => regex.slice(3, -3).split('|'); 

