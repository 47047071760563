import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from "vardogyir-ui";
import { getValidationHelperText, validatePattern } from '../../utils/inputValidation';

const styles = theme => ({
    root: {
        width: '100%',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    textroot: {
        width: '100%',
        margin: 'auto'
    },
    annotationShortText: {
        border: "1px solid #d5d5d5",
        paddingBottom: "5px",
        paddingLeft: "24px",
        borderRadius: "4px",
        fontSize: "14px",
        marginTop: "5px",
        overflow: "hidden",
        minHeight: "30px"
    }
});

class SingleLinetext extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            answer: ''

        };

    }

    componentDidMount() {
        let answer_data = this.props.form.answer_data[this.props.data.key];
        if (answer_data && answer_data.answer) {
            this.setState({ answer: this.props.form.answer_data[this.props.data.key].answer[0] })
        } else {
            if (this.props.data.data.toString() && this.state.answer == '') {
                this.setState({ answer: this.props.data.data })
                this.props.getDataFromComponent({ 'text_answer': this.props.data.data }, this.props.data.key, this.props.data.question_text, this.props.data.data)
            }

            if ((this.props.data.auto.toString()) && !this.props.form.answer_data[this.props.data.key]) {
                this.props.getDataFromComponent({ 'text_answer': this.props.data.auto }, this.props.data.key, this.props.data.question_text, this.props.data.data)
                this.setState({ answer: this.props.data.auto })
            }
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.data.data != prevProps.data.data) {
            //prop has been changed
            //this is what trigger this
            this.props.getDataFromComponent({ 'text_answer': this.props.data.data }, this.props.data.key, this.props.data.question_text, this.props.data.data)
            this.setState({ answer: this.props.data.data })
        }

    }

    handleOnChange = (event) => {
        this.setState({ answer: event.target.value })
    }

    handleOnBlur = (event) => {
        let text = { 'text_answer': event.target.value }
        this.props.getDataFromComponent(text, this.props.data.key, this.props.data.question_text, this.props.data.data)

    };

    doNothing = () => {}

    render() {
        const { classes, data, isAnnotationModeOn } = this.props;

        try {
            return (
                <div className={classes.root}>
                    {!isAnnotationModeOn ? <TextField
                        fontSize={14}
                        value={this.state.answer}
                        disabled={this.props.data.read_only}
                        Theme={'blue'}
                        defaultValue={this.props.data.auto ? this.props.data.auto : this.props.data.data}
                        placeholder={this.props.data.data.placeholder}
                        error={!validatePattern(this.state.answer, this.props.data.validation)}
                        helperText={getValidationHelperText(this.state.answer, this.props.data.validation)}
                        variant="outlined"
                        label={this.props.data.data.label}
                        onChange={this.props.data.read_only ? this.doNothing : this.handleOnChange}
                        onBlur={this.props.data.read_only  ? this.doNothing : this.handleOnBlur}
                    /> : <div className={classes.annotationShortText} id={`${data.key}-answer-text`}>
                    {this.state.answer}
                    </div>}
                </div>
            );
        } catch (e) {
            return (<h5>Error encountered</h5>)
        }

    }
}


const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {};


SingleLinetext.propTypes = {
    classes: PropTypes.object.isRequired,

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SingleLinetext));
