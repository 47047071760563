import {
    Button,
    AlertMessage as AlertMsg,
    PopupAction as DialogAction,
    PopupContent as DialogContent
  } from "vardogyir-ui";
  import {
    Authenticator
  } from "aws-amplify-react";
  import {
    Grid,
    Paper,
    Typography,
    withStyles,
    CircularProgress,
    AppBar,
    Toolbar,
    List,
    ListItem,
    Hidden,
    IconButton,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle
  } from "@material-ui/core";
  import {
    addPageData,
    goBackToANY,
    unmountForm,
    formLoading,
    goBackToPrevious,
    fetchNextPageData,
    addPageDataCalendar,
    addPageDataTable,
    fetchFormComments,
    updateFormState
  } from "../store/form/actions";
  import {
    createDefaultCondition,
    dynamicRender
  } from "./form_tools/dynamicRender";
  import "./Form.css";
  import uuid from "uuid";
  import axios from "axios";
  import config from "../config";
  import FadeIn from "react-fade-in";
  import PropTypes from "prop-types";
  import { connect } from "react-redux";
  import React, { Component } from "react";
  import RawText from "./component/RawText";
  import CustomMap from "./component/Map";
  import Comment from "./component/Comment";
  import Calendar from "./component/Calendar";
  import TableInput from "./component/Table/TableInput";
  import Freetext from "./component/Freetext";
  import { CustomSignIn } from "./CustomSignIn";
  import ListInput from "./component/ListInput";
  import SaveDialog from "./component/SaveDialog";
  import { styles } from "./form_tools/formStyle";
  import Amplify, { Auth, Hub } from "aws-amplify";
  import NumberInput from "./component/NumberInput";
  import UserAccount from "./component/UserAccount";
  import UploadMedia from "./component/UploadMedia";
  import VideoEmbed from "./component/VideoEmbed";
  import DrawableMap from "./component/DrawableMap";
  import SubmissionReceipt from "./SubmissionReceipt";
  import SingleSelect from "./component/SingleSelect";
  import Singlechoice from "./component/Singlechoice";
  import FlashOnIcon from "@material-ui/icons/FlashOn";
  import { parseJson } from "./form_tools/extraFunction";
  import Multiplechoice from "./component/Multiplechoice";
  import SingleLinetext from "./component/SingleLinetext";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
  import ExpansionPanel from "@material-ui/core/ExpansionPanel";
  import { answerDecoder } from "./form_tools/answerEncoderDecoder";
  import RequiredQuestionAlert from "./component/RequiredQuestionAlert";
  import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
  import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
  import MenuIcon from "@material-ui/icons/Menu";
  import SaveIcon from "@material-ui/icons/Save";
  import CoverImage from "./CoverImage";
  import { Helmet } from "react-helmet";
  import { format } from "./utils/Date";
  import {
    parsePlaceholder
  } from "./utils/detectAnswer";
  import Tooltip from "@material-ui/core/Tooltip";
  import ESignature from "./component/ESignature";
  import { parseAnswerData, transpose } from "./component/Table/Util/Helpers";
  import FilePreview from "./component/FilePreview";
  import CommentBubble from "./component/CommentBubble";
  import {
    uniqueNamesGenerator,
    colors,
    animals
  } from "unique-names-generator";
  import FormSubmitLoader from "./FormSubmitLoader";
  import FormSkeletonLoader from "./FormSkeletonLoader";
  import { AutosaveFooter, AutoSaveIcon, AutoSaveTooltip, FooterText, StyledSaveIcon, TooltipText, AnnotateIcon, AnnotateSideIcon, AnnotateAvatar, StyledBadge, AnnotateButton, Chat, ShowAnnotateButton } from "./component/FormStyledComponents";
  import moment from "moment";
  import Popover from "./TextAnnotations/Popover";
  import ThreadSidebar from "./TextAnnotations/ThreadSidebar";
  import { send_request_graphql } from "./TextAnnotations/Request";
  import { GET_HIGHLIGHTS, GET_HIGHLIGHT_COUNT } from "./TextAnnotations/Util/GraphQLQueries";
  import { addHighlight, removeAnnotationHighlights } from "./TextAnnotations/Util/AnnotationHelpers";
import RichTextEditor from "./component/RichTextEditor";
import NavigationMenu from "./component/NavigationSidebar/NavigationMenu";
import SubmitButtons from "./component/SubmitButtons";
import DisplayDatabaseContent from "./component/DatabaseContent/DisplayDatabaseContent";
import SubmitDialogs from "./component/SubmitDialogs";
import RepeatableSectionsInput from "./component/Table/RepeatableSectionsInput";
import { calculateSectionsToShow } from "./component/Table/Util/RSUtil";
import FailedSubmissionDialog from "../FailedSubmissionDialog";
import { validatePattern } from '../utils/inputValidation';

  var _ = require("lodash");
  var FormulaParser = require("hot-formula-parser").Parser;
  var parser = new FormulaParser();

  parser.setFunction("equal", function (params) {
    return params.toString();
  });
  
  const uniqueNamesGeneratorConfig = {
    dictionaries: [colors, animals],
    separator: " ",
    style: "capital",
    length: 2
  };
  
  // Error messages
  const SignInRequired = "Sign in required.";
  const FormCannotBeLoaded = "Form cannot be loaded.";
  const FormAccessError = "Access error.";
  const SessionAlreadySubmittedError = "Session has already been submitted.";
  const SessionTerminatedError = "Session has been terminated.";
  const FormUnavailableError = "Component is unavailable.";

  const CALENDAR_ANSWER = "calendar_answer";
  const TABLE_ANSWER = "table_answer";
  const REPEATABLE_SECTION_ANSWER = "repeatable_sections_answer";
  
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.REGION,
      userPoolId: config.USER_POOL_ID,
      userPoolWebClientId: config.APP_CLIENT_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)(change to 'localhost' to work on local)
        domain: window.location.host.includes("localhost")
          ? "localhost"
          : config.DOMAIN,
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        expires: 1,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
        sameSite: "none"
      }
    }
  });

class Form extends Component {
    state = {
      isTest: this.props.match.params.test_id !== undefined,
      answer: {},
      req_id: "",
      summary: "",
      idToken: "",
      form_id: "",
      submMsg: "",
      password: "",
      form_logo: "",
      sended: false,
      AlertD: false,
      form_title: "",
      currentStep: 0,
      authData: null,
      loading: false,
      form_oauth2: "",
      authError: null,
      projectName: "",
      response: false,
      finished: false,
      processD: false,
      required_list: [],
      required_list_table: {},
      saveDialog: false,
      submissionId: null,
      trueCanReadOnly: false,
      canReadOnly: false,
      isSingleUse: false,
      canInitData: false,
      visible_subm: false,
      projectSessionId: "",
      authState: "loading",
      visible_oauth2: false,
      pageProcessing: false,
      receiptState: "hidden",
      canAddSubmission: false,
      canSaveSubmission: true,
      canSubmitSubmission: true,
      canEditSubmission: false,
      parentThreadId: null,
      numRows: null,
      exportType: null,
      tableCells: null,
      threadId: null,
      waitingStateThreadId: null,
      waitingStateParentThreadId: null,
      AlertMsg: { message: "", status: 200 },
      pagesMeta: [],
      openMainMenu: false,
      previewMode: false,
      logoSrc: "",
      coverImage: "",
      pagesErrorDialog: false,
      pagesError: [],
      linkedComponent: false,
      timezone: "Australia/Hobart",
      userName: "",
      displayName: "",
      username: "Anonymous",
      testResult: null,
      branding: true,
      isSubmitting: false,
      hasBeenSubmitted: false,
      initialLoad: true,
      formType: null,
      hasAutoSaveEnabled: false,
      lastSaved: null,
      isAnnotationModeOn: false,
      isThreadSidebarOpen: false,
      highlightedAnnotation: null,
      rerenderSidebar: null,
      annotationCount: null,
      canAnnotateContent: false,
      isTerminated: false,
      showResubmitDialog: false,
      showSubmissionDialog: false,
      formFailures: {},
      showFailedSubmissionDialog: false
    };
  
    visitedPages = new Set();
  
    constructor(props) {
      super(props);
  
      //setup Amplify Hub
      Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signIn":
            this.processIdToken()
            .then((token) => {
              this.initData(token);
            })

            this.setState({
              authState: "signedIn",
              authData: data.payload.data,
            });
            break;
          case "signIn_failure":
            this.setState({
              authState: "signIn",
              authData: null,
              authError: data.payload.data,
            });
            break;
          default:
            break;
        }
      });
  
      Auth.currentUserInfo()
        .then((user) => {
          if (user != null) {
            this.setState({ authState: "signedIn" });

            if (user) {
              this.setState({
                username: user.username,
              });
            }
          }
        })

        .catch((err) => {
          console.log("NOT AUTHENTICATED");
          console.log(err);
          this.setState({
            username: uniqueNamesGenerator(uniqueNamesGeneratorConfig),
          });
        });
  
      //default visited the first page 0
      this.visitedPages.add(0);
    }
  
    handleToPage = (index) => {
      this.props.dispatchgoBackToANY(index);
    };
  
    submitForm = async (reqId, answer_data) => {
      let usageDataMapping = new FormData();
      const usageMapping = {};
      usageMapping.formId = this.state.form_id;
      usageMapping.sessionId = this.props.match.params.session_id;
      usageMapping.status = "PROCESS";
      let FormDataSubmission = new FormData();
      const formSubmission = {};
      formSubmission.formId = this.state.form_id;
      formSubmission.upload = [];
      const isTest = this.props.match.params.test_id !== undefined;
  
      for (var obj in answer_data) {
        if (
          answer_data[obj].answer &&
          answer_data[obj].answer[0] instanceof File
        ) {
          formSubmission.upload.push({
            key: answer_data[obj].key,
            answer: [],
            question_text: answer_data[obj].question_text,
          });
          FormDataSubmission.append("file", answer_data[obj].answer[0]);
          delete answer_data[obj];
        }
      }
      const req_id = reqId || uuid();
      usageMapping.reqId = req_id;
      var idToken = await this.processIdToken();
  
      if (answer_data && answer_data.project_name) {
        usageMapping.displayName = answer_data.project_name.answer[0];
      }
      usageMapping.displayName = this.getDisplayName(answer_data);
  
      formSubmission.data = answer_data;
      this.setState({ req_id: req_id });
      FormDataSubmission.append("data", JSON.stringify(formSubmission));
      FormDataSubmission.append("req_id", req_id);
      FormDataSubmission.append("submissionUrl", this.props.form.submissionUrl);
      FormDataSubmission.append("sessionID", usageMapping.sessionId);
      FormDataSubmission.append(
        "projectSessionId",
        this.props.form.projectSessionId
      );

      FormDataSubmission.append("projectId", this.state.projectId);
      FormDataSubmission.append("projectVersion", this.state.projectVersion);
  
      // Set thread
      FormDataSubmission.append("parentThreadId", this.state.parentThreadId);
      FormDataSubmission.append("threadId", this.state.threadId);
  
      let formSessionURL =
        "https://form.workflow86.com/form/" +
        this.state.form_id +
        "/" +
        this.props.match.params.session_id;
  
      if (isTest) {
        FormDataSubmission.append("projectSessionMode", "TEST");
        FormDataSubmission.append(
          "projectTestId",
          this.props.match.params.test_id
        );
        FormDataSubmission.append("latestTestProjectSessionId", req_id);
  
        formSessionURL =
          "https://form.workflow86.com/form/form_test/" +
          this.state.form_id +
          "/" +
          this.props.match.params.test_id +
          "/" +
          this.props.match.params.session_id;
      }
  
      FormDataSubmission.append("formSessionURL", formSessionURL);
  
      // Set the waiting state thread
      FormDataSubmission.append(
        "waitingStateThreadId",
        this.state.waitingStateThreadId
      );
      FormDataSubmission.append(
        "waitingStateParentThreadId",
        this.state.waitingStateParentThreadId
      );
  
      FormDataSubmission.append("isSingleUse", this.state.isSingleUse);
      FormDataSubmission.append("formType", this.state.formType);
  
      let usagePromise = Promise.resolve(1);
  
      if (config.USAGE_ADDRESS && config.USAGE_ADDRESS != "") {
        usagePromise = axios
          .post(`${config.USAGE_ADDRESS}/usage`, JSON.stringify(usageMapping), {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: idToken,
            },
          })
          .catch((e) => {
            console.log("Error during call to Usage Service " + e);
          });
      }
      return await usagePromise.then(() => {
        axios
          .post(`${config.API_ADDRESS}/form_submit`, FormDataSubmission, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: idToken,
            },
          })
          .then((res) => {
            this.handleAfterFormSubmit();
  
            if (isTest) {
              this.setState({
                testResult: {
                  createdAt: new Date(),
                  projectSessionId: reqId,
                },
              });
            }
          })
          .catch((e) => {
            this.setState((state) => {
              return {
                receiptState: "failure",
                sended: false,
                response: true,
                finished: true,
                isSubmitting: false,
              };
            });
          });
      });
    };
  
    isAuthenticated = async () => {
      try {
        var currentUser = await Auth.currentSession();
        if (currentUser) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    };

  uploadFileAndUpdateAnswer= async(value, reqId) => {
    let fileToUpload = [];
    let fileUploaded = []
    // fix if an old submitted session is submitted
    if (typeof value.answer[0] === "string" && value.answer[0].startsWith("https://"))
    {
      return value.answer[0];
    }

    for (let answerKey of value.answer[0]) {
      if (answerKey instanceof File) {
        fileToUpload.push(answerKey)
      } else {
        fileUploaded.push(answerKey)
      }
    }
    if(fileToUpload && fileToUpload[0]) {

      // upload only file that are just uploaded
      let obj = await this.zipAndUploadFile(
          fileToUpload,
          reqId,
          value.key
      );
      // add it to the previous answer
      fileUploaded.push(...obj["obj_of_file_uploads"]);
    }
    return fileUploaded;
  }
  
  traverseAndFlatten = async (currentNode, action, reqId, failedUploads) => {
    for (var key in currentNode) {
      if (!currentNode.hasOwnProperty(key)) continue;

      let value = currentNode[key];

      if (!value || !value.format) continue;

      // E_signature
      if (value.format == "esignature_answer") {
        if (action == "SAVE") {
          //not saving esignature on save
          currentNode[key] = { ...value, answer: [value.answer[0]] };
        } else if (action == "SUBMIT_SESSION") {
          console.log("submit_session");

          if (value && value.answer && value.answer[0]) {
            currentNode[key] = { ...value, answer: [value.answer[0]] };
          }
        } else if (action == "SUBMIT") {
          if (value && value.answer && value.answer[0]) {
            if (value.answer[0]) {
              currentNode[key] = { ...value, answer: [value.answer[0]] };
            }
          }
        }
      }

      // File_upload
      if (value.format == "upload_media") {
        if (
          action == "SAVE" &&
          value.answer &&
          value.answer[0].length > 1
        ) {
          try {
            let fileUploaded = await this.uploadFileAndUpdateAnswer(value, reqId);
            currentNode[key] = { ...value, answer: [fileUploaded] };
          } catch (e) {
            console.log(e);
            if (failedUploads && !(value.key in failedUploads)) failedUploads[value.key] = value; 
          }
        } else if (
          action == "SAVE" &&
          value.answer &&
          value.answer[0][0] instanceof File
        ) {
          try {
            let obj = await this.uploadFile(value.answer[0][0], reqId);
            currentNode[key] = { ...value, answer: [obj] };
          } catch (e) {
            console.log(e);
            if (failedUploads && !(value.key in failedUploads)) failedUploads[value.key] = value; 
          }
        }

        if (
          (action == "SUBMIT" || action == "SUBMIT_SESSION") &&
          value.answer &&
          value.answer[0].length > 1
        ) {
          try {
            let fileUploaded = await this.uploadFileAndUpdateAnswer(value, reqId);
            currentNode[key] = { ...value, answer: [fileUploaded] };
          } catch (e) {
            console.log(e);
            if (failedUploads && !(value.key in failedUploads)) failedUploads[value.key] = value;
          }

        } else if (
          (action == "SUBMIT" || action == "SUBMIT_SESSION") &&
          value.answer
        ) {
          let obj = value.answer[0];
          if (value.answer[0][0] instanceof File)
            try {
              obj = await this.uploadFile(value.answer[0][0], reqId);
              currentNode[key] = { ...value, answer: [obj] };
            } catch (e) {
              console.log(e);
              if (failedUploads && !(value.key in failedUploads)) failedUploads[value.key] = value;
            }
        }
      }
    }

    return currentNode;
  };
  
    uploadFile = async (files, reqId) => {
      let idToken = await this.processIdToken();
      let formData = new FormData();
      formData.append("file", files);
      formData.append("formSessionId", this.props.match.params.session_id);
      formData.append("formId", this.props.match.params.form_id);
      formData.append("projectSessionId", this.state.projectSessionId || reqId);
      formData.append("projectId", this.state.projectId);
      formData.append("projectVersion", this.state.projectVersion);
  
      const json = await axios.post(
        `${config.API_ADDRESS}/form_upload`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: idToken,
          },
        }
      );
      if (json && json.data) {
        return {
          id: json.data[0].id,
          name: json.data[0].fileName,
          link: `${config.FILE_ADDRESS}/${json.data[0].id}`,
        };
      }
    };
  
    zipAndUploadFile = async (files, reqId, zipName) => {
  
      let idToken = await this.processIdToken();
      let formData = new FormData();
      
      for (let file of files) {
        formData.append("file", file);
      }
  
      formData.append("formSessionId", this.props.match.params.session_id);
      formData.append("formId", this.props.match.params.form_id);
      formData.append("projectSessionId", this.state.projectSessionId || reqId);
      formData.append("projectId", this.state.projectId);
      formData.append("projectVersion", this.state.projectVersion);
      formData.append("zipName", zipName);
  
      const json = await axios.post(
        `${config.API_ADDRESS}/form_upload`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: idToken,
          },
        }
      );
      if (json && json.data) {
        let obj_of_file_uploads=[];
        for(let key in json.data)
        {
          let file_data= {
            id: json.data[key].id,
            name: json.data[key].fileName,
            link: `${config.FILE_ADDRESS}/${json.data[key].id}`
          }
          obj_of_file_uploads.push(file_data);
        }
        return {
          obj_of_file_uploads
        };
      }
    };

    handleAfterFormSubmit = async () => {
      // If no redirect, readonly ON, canEdit ON/OFF
      if (this.state.trueCanReadOnly || this.state.canReadOnly || this.state.canEditSubmission) {
        // Need to show dialog
        return this.setState((state) => {
          return {
            sended: false,
            response: true,
            finished: true,
            isSubmitting: false,
            showSubmissionDialog: true,
            canReadOnly: this.state.trueCanReadOnly || this.state.canReadOnly,
            hasBeenSubmitted: true,
            showResubmitDialog: false,
          };
        });
      }
      
      // If read only OFF and canEdit OFF ===> !this.state.canReadOnly && !this.state.canEditSubmission
      // Leave to else statement for default logic
      else {
        // Need to redirect to finish receipt
        return this.setState((state) => {
          return {
            sended: false,
            response: true,
            finished: true,
            isSubmitting: false,
            receiptState: "success",
          };
        });
      }
    }

    handleSubmit = async () => {
      // Handle Submit
      this.setState({ isSubmitting: true });
      const { answer_data } = this.props.form;
      this.clearDeletedFields();

      if (this.isAuthenticated() === false) {
        this.setState({ authState: "signIn", isSubmitting: false });
      } else {
        if (this.checkAllAnswered() == false) {
          this.setState({ isSubmitting: false });
          return;
        }
  
        try {
          this.setState((state) => {
            return {
              currentStep: 0,
              processD: true,
              sended: true,
            };
          });
          const reqId = uuid();
          this.setState({ pageProcessing: true });

          if (
            !this.props.match.params.session_id ||
            this.props.match.params.session_id.trim() == ""
          ) {
            //no session ID, so we need to create one here
            this.props.match.params.session_id = uuid();
          }
  
          let failedUploads = {}; // Define a list of keys that failed to upload

          let answer_data_forSubmit = await this.traverseAndFlatten(
            answer_data,
            "SUBMIT",
            reqId,
            failedUploads
          );
  
          let answer_data_formSession = JSON.parse(
            JSON.stringify(answer_data_forSubmit)
          );
  
          let data = await this.traverseAndFlatten(
            answer_data_formSession,
            "SUBMIT_SESSION",
            reqId,
            failedUploads
          );
  
          const { session_id, form_id, test_id } = this.props.match.params;

          const isTest = test_id !== undefined;

          let sessionURL = `${config.API_ADDRESS}/form_session?session_id=${session_id}&form_id=${form_id}`;
  
          if (isTest) {
            sessionURL = `${config.API_ADDRESS}/test_form_session?session_id=${session_id}&form_id=${form_id}`;
          }

          let projectSessionId = this.state.projectSessionId;
          if (this.state.formType === "form") {
             // If it's a trigger form
             projectSessionId = reqId;
          }
          
          // Handle files failing to upload on submission here
          if (failedUploads && Object.keys(failedUploads).length > 0) {
            const saveData = data ? { ...data } : {}; // Make a copy of the data

            // Remove the failed uploads from the save data
            Object.keys(failedUploads).map((key) => {
              if (key in saveData) delete saveData[key];
            });

            // Perform the save
            let hasSaveFailed = false;
            try {
              const formSessionID = this.props.match.params.session_id
              await this.makeFormSaveRequest(sessionURL, saveData, formSessionID);
 
              // Handle changing URL
              let formSessionURL = `/form/${this.props.match.params.form_id}/${formSessionID}`;
              if (test_id !== undefined) formSessionURL = `/form/form_test/${this.props.match.params.form_id}/${test_id}/${formSessionID}`;
              this.props.history.push(formSessionURL);
            } catch (e) {
              console.log(e);
              hasSaveFailed = true;
            }

            // Open submission error dialog
            this.setState({ formFailures: { failedUploads, failedSave: hasSaveFailed }, showFailedSubmissionDialog: true });
            this.handleErrorState();

            return;
          }

          // Set submitted by
          data["submittedBy"] = {
            userName: this.state.userName,
            displayName: this.state.displayName,
          };

          axios(sessionURL, {
            method: "post",
            data: {
              data: data,
              session_id: this.props.match.params.session_id,
              form_id: this.props.match.params.form_id,
              password: this.state.password,
              isSingleUse: this.state.isSingleUse,
              submissionId: reqId,
              canReadOnly: this.state.canReadOnly,
              projectId: this.state.projectId,
              projectVersion: this.state.projectVersion,
              projectSessionId: projectSessionId,
              parentThreadId: this.state.parentThreadId,
              threadId: this.state.threadId,
              latestTestProjectSessionId: reqId,
              waitingStateThreadId: this.state.waitingStateThreadId,
              waitingStateParentThreadId: this.state.waitingStateParentThreadId,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then(async (res) => {
              return this.submitForm(reqId, answer_data_forSubmit);
            })
            .then((data) => {
              let hasRedirected = false;
  
              if (
                this.state.receiptState &&
                this.state.receiptState == "hidden"
              ) {
                if (this.state.linkedComponent && this.state.req_id) {
                  let projectSessionId = this.state.req_id;
  
                  if (
                    this.state.projectSessionId &&
                    this.state.projectSessionId != ""
                  ) {
                    projectSessionId = this.state.projectSessionId;
                  }
  
                  if (isTest) {
                    this.props.history.push(
                      `/form_test/${this.props.match.params.form_id}/${this.props.match.params.session_id}/${projectSessionId}/${test_id}`
                    );
                  } else {
                    this.props.history.push(
                      `/form/${this.props.match.params.form_id}/${this.props.match.params.session_id}/${projectSessionId}`
                    );
                  }
  
                  hasRedirected = true;
                  return;
                }
              }
  
              if (!hasRedirected) {
                if (!isTest) {
                  this.props.history.push(
                    "/form/" +
                      this.props.match.params.form_id +
                      "/" +
                      this.props.match.params.session_id
                  );
                } else {
                  this.props.history.push(
                    "/form/form_test/" +
                      form_id +
                      "/" +
                      test_id +
                      "/" +
                      session_id
                  );
                }
              }
            })
            .then(() => {
              this.setState((state) => {
                return { currentStep: 1, pageProcessing: false };
              });
            })
            .catch((e) => {
              console.log(e);
              this.setState((state) => {
                return {
                  currentStep: 1,
                  receiptState: "failure",
                  response: true,
                  finished: true,
                  sended: false,
                  isSubmitting: false,
                };
              });
            });
        } catch (e) {
          this.handleErrorState(e);
        }
      }
    };

    handleErrorState(error) {
      console.log(error);
      this.setState((state) => {
        return {
          processD: true,
          currentStep: 0,
          response: true,
          finished: true,
          sended: false,
          isSubmitting: false,
          pageProcessing: false,
        };
      });
    }
  
    getCookie(name) {
      var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
      return v ? v[2] : null;
    }
  
    async processIdToken() {
      try {
        var data = await Auth.currentSession();
        return data.idToken.jwtToken;
      } catch (err) {
        console.log(err);
      }
    }
  
    checkCanExecute() {
      return axios(
        config.API_ADDRESS +
          "/isSecureComponent?form_id=" +
          this.props.match.params.form_id,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((isSecureResponse) => {
          let isSecure = isSecureResponse.data.data;
          if (isSecure) {
            //secure component
            this.setState({ form_oauth2: "oauth2" });
            return this.isAuthenticated();
          } else {
            //public component
            this.setState({ canInitData: true });
          }
        })
        .then((isAuthenticated) => {
          if (isAuthenticated) {
            return this.processIdToken();
          }
        })
        .then((idToken) => {
          if (idToken) {
            return axios(
              config.PROJECT_SERVICE_URL +
                "/canExecuteComponent/" +
                this.props.match.params.form_id,
              {
                method: "get",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: idToken,
                },
              }
            );
          }
        })
        .then((canExecuteResponse) => {
          if (canExecuteResponse) {
            let canExecute = canExecuteResponse.data;
            if (!canExecute) {
              //not allowed
              this.props.history.push("/form_error");
            }
  
            this.setState({ canInitData: true });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    initData(idToken) {
      let getSessionPromise = Promise.resolve(1);
      // let getCommentsPromise = Promise.resolve(1);
      let isIncludeSingleUseForm = false;
      const isTest = this.props.match.params.test_id !== undefined;
  
      if (
        this.props.match.params.session_id &&
        this.props.match.params.session_id.trim() != ""
      ) {
        isIncludeSingleUseForm = true;
  
        if (this.props.match.params.test_id !== undefined) {
          getSessionPromise = axios(
            config.API_ADDRESS +
              "/test_form_session/?form_id=" +
              this.props.match.params.form_id +
              "&session_id=" +
              this.props.match.params.session_id,
            {
              method: "get",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken
              },
            }
          );
        } else {
          getSessionPromise = axios(
            config.API_ADDRESS +
              "/form_session?form_id=" +
              this.props.match.params.form_id +
              "&session_id=" +
              this.props.match.params.session_id,
            {
              method: "get",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken
              },
            }
          );
        }
        this.props.dispatchFetchFormComments({
          session_id: this.props.match.params.session_id,
          isTest,
        });
      }
  
      getSessionPromise
        .then((formSession) => {
          let projectVersion = 0;
          let projectSessionId = undefined;
          let latestTestProjectSessionId = null;
          let parentThreadId = null;
          let threadId = null;
          let waitingStateThreadId = null;
          let waitingStateParentThreadId = null;
          let submissionId = "";
          let session_id = "";
          let lastSaved = null;
  
          if (formSession && formSession.data && formSession.data.data) {
            this.setHasBeenSubmitted(formSession);
            if (!formSession.data.data.canReadOnly)
              this.setState({ canReadOnly: formSession.data.data.canReadOnly });
            this.setState({ submissionId: formSession.data.data.submissionId });
  
            // Do checks here for thread
            if (formSession.data.data.threadId != null) {
              parentThreadId = formSession.data.data.parentThreadId;
              threadId = formSession.data.data.threadId;
            }
  
            waitingStateThreadId = formSession.data.data.waitingStateThreadId
              ? formSession.data.data.waitingStateThreadId
              : null;
            waitingStateParentThreadId = formSession.data.data
              .waitingStateParentThreadId
              ? formSession.data.data.waitingStateParentThreadId
              : null;
            projectVersion = formSession.data.data.projectVersion;
            projectSessionId = formSession.data.data.projectSessionId;
            latestTestProjectSessionId =
              formSession.data.data.latestTestProjectSessionId;
            submissionId = formSession.data.data.submissionId;
            session_id = formSession.data.data.session_id;
            lastSaved = formSession.data.data.update_at;
          }
  
          if (
            this.props.match.path &&
            this.props.match.path.includes("form_preview") &&
            projectVersion == 0
          ) {
            //preview mode
            this.state.previewMode = true;
            projectVersion = this.props.match.params.projectVersion;
            isIncludeSingleUseForm = true;
          }
  
          let isLatestDraft;
          if (isTest) {
            isLatestDraft = "&isLatestDraft=true";
          } else {
            isLatestDraft = "";
          }
  
          if (latestTestProjectSessionId !== null && isTest) {
            axios(
              config.API_ADDRESS +
                "/test_result_with_session_id?projectSessionId=" +
                latestTestProjectSessionId,
              {
                method: "get",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            ).then((res) => {
              const data = res.data;
              const { testResult } = data;
              this.setState({ testResult });
            });
          }
  
          axios(
            config.API_ADDRESS +
              "/form_meta?form_id=" +
              this.props.match.params.form_id +
              `&session_id=${session_id}` +
              `&isTest=${isTest}` +
              "&includeSingleUseForm=" +
              isIncludeSingleUseForm +
              "&projectVersion=" +
              projectVersion +
              `&projectSessionId=${projectSessionId}` +
              `&threadId=${threadId}` +
              `&submissionId=${submissionId}` +
              isLatestDraft,
            {
              method: "get",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken
              },
            }
          )
            .then(async (res) => {
              let data = res.data.data;
  
              if (data && data.pages) {
                let pageKeys = Object.keys(data.pages);
  
                pageKeys.forEach((aPageKey, index) => {
                  let pageTitle = "Page " + (index + 1);
  
                  let aPage = data.pages[aPageKey];
  
                  if (
                    aPage.title.view.data.default &&
                    aPage.title.view.data.default.trim() != ""
                  ) {
                    pageTitle = aPage.title.view.data.default.trim();
                  }
  
                  this.state.pagesMeta.push({ pageTitle: pageTitle });
                });
              }
  
              this.setState({
                form_logo: data.form_logo,
                form_title: data.form_title,
                form_oauth2: data.form_oauth2,
                submMsg: data.submMsg,
                canAddSubmission: data.canAddSubmission,
                canSaveSubmission:
                  data.canSaveSubmission !== undefined
                    ? data.canSaveSubmission
                    : true,
                canSubmitSubmission:
                  data.canSubmitSubmission !== undefined
                    ? data.canSubmitSubmission
                    : true,
                canEditSubmission:
                  data.canEditSubmission == null ? true : data.canEditSubmission,
                canReadOnly: this.state.canReadOnly, //this needs to be here to renew state on reload
                isSingleUse: data.isSingleUse,
                projectId: data.projectId,
                projectVersion: data.projectVersion,
                projectSessionId: projectSessionId,
                pagesMeta: this.state.pagesMeta,
                logo: data.logo,
                coverImage: data.coverImage,
                linkedComponent: data.linkedComponent,
                lastModified: data.lastModified,
                parentThreadId: parentThreadId,
                threadId: threadId,
                numRows: data.numRows,
                exportType: data.exportType,
                tableCells: data.tableCells,
                branding: data.branding,
                waitingStateThreadId: waitingStateThreadId,
                waitingStateParentThreadId: waitingStateParentThreadId,
                formType: data.formType,
                lastSaved: lastSaved,
                canAnnotateContent: data.canAnnotateContent ? true : false,
                isTerminated: data.isTerminated,
                trueCanReadOnly: data.trueCanReadOnly,
              });
              if (this.state.canReadOnly !== false)
                this.setState({
                  canReadOnly: data.canReadOnly,
                });
  
              projectVersion = data.projectVersion;
              return res;
            })
            .then(async (formMeta) => {
              this.handleAutoSave(formMeta, session_id);
              return formMeta;
            })
            .then((formMeta) => {
              // Update the form state here
              // We do this so we could remove the call to /page
              // and calling /form_session twice
              this.props.dispatchUpdateFormState(formSession.data, formMeta.data);
            })
            .then(() => {
              if (!projectSessionId || projectSessionId === "null") return;
  
              // This means it's an assign task
              let reqData = {
                session_id: projectSessionId,
                component_id: this.props.match.params.form_id,
                thread_id: threadId,
                user: this.state.username,
              };
  
              axios(`${config.API_ADDRESS}/save_task_loaded`, {
                method: "post",
                data: reqData,
              })
                .then(() => console.log("Successfully saved task load"))
                .catch((e) => console.log(e));
            })
            .then(async () => {
              // Get the number of annotations
              if (!this.state.canAnnotateContent || this.state.previewMode || this.state.canReadOnly || this.state.isTest) return;
              let url = this.props.match.params.form_id;
              send_request_graphql(url, GET_HIGHLIGHT_COUNT(this.props.match.params.form_id, this.props.match.params.session_id))
                  .then((res) => {
                      this.setState({ annotationCount: res.data.getNumberOfAnnotationsByFormIdAndFormSessionId })
                  })
                  .catch((e) => {
                      console.log(e);
                  });
            })
            .catch((e) => {
              this.handleFormError(e);
            });
        })
        .then(async () => {})
        .catch((e) => {
          this.handleFormError(e);
        });
    }

    setHasBeenSubmitted = (session) => {
      try {
        let by = session.data.data.data.submittedBy;

        if (by.displayName !== null || by.userName !== null) this.setState({ hasBeenSubmitted: true });
        else this.setState({ hasBeenSubmitted: false });

      } catch (e) { 
        this.setState({ hasBeenSubmitted: false });
       }
    }

    handleFormError = (e) => {
      try {
        if (!e.response) this.props.history.push("/form_error");
        let err = e.response.data;

        switch (err.msg) {
          case FormCannotBeLoaded:
            this.props.history.push("/form_error");
            break;
          case SignInRequired:
            this.setState({ form_oauth2: "oauth2" });
            break;
          case FormAccessError:
            this.props.history.push("/form_error/access");
            break;
          case SessionAlreadySubmittedError:
            this.props.history.push("/form_error/already_submitted");
            break;
          case SessionTerminatedError:
            this.props.history.push("/form_error/terminated");
            break;
          case FormUnavailableError:
            this.props.history.push("/form_error/unavailable");
            break;
          default:
            this.props.history.push("/form");
            break;
        }
      } catch (e) {
        this.props.history.push("/form_error"); // Generic
      }
    }
  
    // Handles the auto-save logic
    handleAutoSave = async (formMeta, session_id) => {
      if (this.state.previewMode || this.state.canReadOnly) return; // If preview or read only, don't save
  
      // Check if either can save submission or autosave are false
      // If so, return, else continue
      if (
        !formMeta.data ||
        !formMeta.data.data ||
        !formMeta.data.data.canSaveSubmission ||
        !formMeta.data.data.autoSave
      )
        return;
  
      // Need to know if it is a form session or not
      // If there is no session_id, we need to create one and then save
      if (!session_id || session_id.trim() == "") {
        let { form_id, test_id } = this.props.match.params;
        
        let failedUploads = {};
        const data = await this.traverseAndFlatten(this.props.form.answer_data, "SAVE", null, failedUploads);

        const saveData = data ? { ...data } : {};

        // Remove any failedUploads
        if (failedUploads && Object.keys(failedUploads).length > 0) {
          Object.keys(failedUploads).map((key) => {
            if (key in saveData) delete saveData[key];
          });
        }
          
        const isTest = test_id !== undefined;
  
        // Set url conditionally on whether it's a test or not
        let sessionURL = isTest
          ? `${config.API_ADDRESS}/test_form_session?session_id=${session_id}&form_id=${form_id}`
          : `${config.API_ADDRESS}/form_session?session_id=${session_id}&form_id=${form_id}`;
  
        session_id = uuid(); // Generate a session id
  
        axios(sessionURL, {
          method: "post",
          data: {
            data: saveData,
            session_id: session_id,
            form_id: this.props.match.params.form_id,
            password: this.state.password,
            projectSessionId: this.state.projectSessionId,
            projectVersion: this.state.projectVersion,
            isSingleUse: this.state.isSingleUse,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(() => {
            this.setState({ pageProcessing: false });
            let formSessionUrl = `/form/${this.props.match.params.form_id}/${session_id}`;
            if (isTest)
              formSessionUrl = `/form/form_test/${this.props.match.params.form_id}/${test_id}/${session_id}`;
            this.props.history.push(formSessionUrl);
            this.createSaveInterval(); // Create the save interval
          })
          .catch((e) => {
            // There was an error creating the form session, just return
            console.log(e);
            return;
          });
      } else {
        // We already have a session so just create the save interval
        this.createSaveInterval();
      }
    };
  
    createSaveInterval = async () => {
      this.setState({ hasAutoSaveEnabled: true }); // Set hasAutoSaveEnabled to true
      // If we reach here, we know that we have to turn on autosaving interval
      this.autoSaveInterval = setInterval(async () => {
        if (this.state.pageProcessing) return; // If page processing, skip save
  
        // Attempt to save
        try {
          // If the tab is visible/open, allow auto-save
          if (document.visibilityState === 'visible') await this.handleSaveProgress(false); // Wait until save has finished
        } catch (e) {
          console.log(e); // Saving has failed, stop processing
          this.handleErrorState(e);
        }
      }, config.AUTOSAVE_INTERVAL * 1000);
    };
  
    addAnnotationHighlights = async (formSessionId) => {
      if (!formSessionId || this.state.previewMode || this.state.canReadOnly) return;
      let formId = this.props.match.params.form_id;
  
      send_request_graphql(formId, GET_HIGHLIGHTS(formId, formSessionId))
          .then((res) => {
              let annotations = res.data.getAnnotationsByFormIdAndFormSessionId;
              
              // Go over annotations and add highlight
              for (let a of annotations) {
                addHighlight(a, this.showSidebarAndHighlight, a.annotationId)
              }
          })
          .catch((e) => {
            console.log('error')
              console.log(e);
          });
    }
  
    showSidebarAndHighlight = (bool, annotationId) => {
      if (!annotationId) this.setState({ isThreadSidebarOpen: bool, highlightedAnnotation: null });
      else this.setState({ isThreadSidebarOpen: bool, highlightedAnnotation: annotationId });
    }
  
    getCoverImageSrc = (crop) => {
      return this.getCroppedImg(this.coverImageRef, crop);
    };
  
    componentDidMount = async () => {
      await this.setTimezone();
      this.setState({ form_id: this.props.match.params.form_id });
  
      try {
        let idToken = await this.processIdToken();
        this.initData(idToken);
      } catch (e) {
        console.log(e);
      }
    };
  
    setTimezone = async () => {
      let idToken = await this.processIdToken();
      if (idToken) {
        await axios
          .get(`${config.USER_ADDRESS}/user-info`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: idToken,
            },
          })
          .then((res) => {
            if (res && res.data) {
              this.setState({
                timezone: res.data.timezone
                  ? res.data.timezone
                  : "Australia/Hobart",
                userName: res.data.userName ? res.data.userName : "",
                displayName: res.data.displayName ? res.data.displayName : "",
              });
            }
          })
          .catch(() => {
            this.setState({
              timezone: "Australia/Hobart",
            });
          });
      }
    };
  
    componentWillUnmount() {
      this.props.dispatchUnmountForm();
      clearInterval(this.autoSaveInterval); // Unmount interval
    }
  
    componentSelector = (format, data, section, key) => {
      data = parsePlaceholder(data, this.props.form);
      switch (format) {
        case "multiple_choice":
          return data.drop_down == "true" ? (
            <ExpansionPanel>
              <ExpansionPanelSummary
                key={data.key}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>{data.key}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {" "}
                <Multiplechoice
                  key={data.key}
                  getDataFromComponent={this.getDataFromComponent.bind(this)}
                  data={data}
                  isAnnotationModeOn={this.state.isAnnotationModeOn}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            <Multiplechoice
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "free_text":
          return (
            <Freetext
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "single_line_text":
          return (
            <SingleLinetext
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "e_signature":
          return (
            <ESignature
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
            />
          );
        case "single_choice":
          return data.drop_down == "true" ? (
            <ExpansionPanel>
              <ExpansionPanelSummary
                key={data.key}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>{data.key}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Singlechoice
                  key={data.key}
                  getDataFromComponent={this.getDataFromComponent.bind(this)}
                  data={data}
                  isAnnotationModeOn={this.state.isAnnotationModeOn}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            <Singlechoice
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "number_input":
          return (
            <NumberInput
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "custom_map":
          return (
            <CustomMap
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
            />
          );
        case "calendar":
          return (
            <Calendar
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "drawable_map":
          return (
            <DrawableMap
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
            />
          );
        case "row_text":
          if (section == "title") {
            return (
              <div>
                <h2>{data.data}</h2>
              </div>
            );
          } else if (section == "answer") {
            return <RawText data={data} isAnnotationModeOn={this.state.isAnnotationModeOn} />;
          } else return <div id={`${key}-question-title`}>{data.data}</div>;
  
        case "single_select":
          return (
            <SingleSelect
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "upload_media":
          return (
            <UploadMedia
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
            />
          );
        case "list_input":
          return (
            <ListInput
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "table":
          return (
            <TableInput
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              tableErrors={this.state.required_list_table}
              setRequiredTableErrors={(updatedErrors) =>
                this.setState({ required_list_table: updatedErrors })
              }
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "repeatable_section":
          return (
            <RepeatableSectionsInput
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
              tableErrors={this.state.required_list_table}
              setRequiredTableErrors={(updatedErrors) =>
                this.setState({ required_list_table: updatedErrors })
              }
              isAnnotationModeOn={this.state.isAnnotationModeOn}
            />
          );
        case "display_database_content":
          return (
            <DisplayDatabaseContent 
              data={data}
              isAnnotationModeOn={this.state.isAnnotationModeOn}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
            />
          )
        case "file_preview":
          return (
            <FilePreview
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
            />
          );
        case "video_embed":
          return (
            <VideoEmbed
              key={data.key}
              getDataFromComponent={this.getDataFromComponent.bind(this)}
              data={data}
            />
          );
        case "rich_text_editor":
          return (
              <RichTextEditor
                  key={data.key}
                  getDataFromComponent={this.getDataFromComponent.bind(this)}
                  data={data}
                  isAnnotationModeOn={this.state.isAnnotationModeOn}
              />
          );
        default:
          return section == "title" ? (
            <div className="componentSelectorTitle">
              <h3 id={`form-title-${key}`} className="form-title">
                {data.data}
              </h3>
            </div>
          ) : (
            <div style={{ textAlign: "left" }}>
              <p>{data.data}</p>
            </div>
          );
      }
    };
  
    getDataFromComponent = (
      data,
      key,
      question_text,
      answer_list,
      outputFormat,
      timezone,
      tableAnswerKeys,
      exportFormat,
    ) => {
      let format = Object.keys(data)[0];
  
      data = answerDecoder(data, answer_list);
  
      if (format == CALENDAR_ANSWER){
        if (!data[0]) return;
        const date = new Date(data[0]);
        const isoString = date.toISOString();
        this.props.dispatchaddPageDataCalendar(
          key,
          data,
          question_text,
          format,
          isoString,
          outputFormat,
          timezone,
        );
      } else if (format === TABLE_ANSWER || format === REPEATABLE_SECTION_ANSWER) {
        const colNames = answer_list ? answer_list.map((item) => { return item.columnName }) : [];

        this.props.dispatchaddPageDataTable(
          key,
          data,
          question_text,
          format,
          exportFormat,
          tableAnswerKeys,
          colNames
        );
      } else this.props.dispatchaddPageData(key, data, question_text, format);
    };
    unWrap_answer = (data, container, current_deepth) => {
      if (typeof data != "string" && typeof data != "number") {
        for (let key in data) {
          if (current_deepth == 0) {
            container.push(<ul>{key} </ul>);
          } else {
            let curr = container[container.length - 1];
            let tmp = React.Children.toArray(curr.props.children);
            tmp.push(<ul>{key}</ul>);
            container[container.length - 1] = React.cloneElement(curr, {
              children: tmp,
            });
          }
          this.unWrap_answer(data[key], container, current_deepth + 1);
        }
      } else {
        if (current_deepth == 0) {
          container.push(<ul>{data} </ul>);
        } else {
          let curr = container[container.length - 1];
          let tmp = React.Children.toArray(curr.props.children);
          tmp.push(
            <ul>
              <li>{data}</li>
            </ul>
          );
          container[container.length - 1] = React.cloneElement(curr, {
            children: tmp,
          });
        }
        this.state.summary = container;
        return container;
      }
    };
  
    checkAllAnswerOnAllPages = () => {
      if (this.props.form.request_data) {
        let pagesKeys = Object.keys(this.props.form.request_data);
  
        let required_list = [];
        for (let a = 0; a < pagesKeys.length; a++) {
          let validationResult = this.doCheckAllAnswers(pagesKeys[a]);
          if (validationResult && validationResult.length > 0) {
            //failed validation
            let aPage = this.props.form.request_data[pagesKeys[a]];
  
            //this.state.pagesMeta.push({pageTitle:pageTitle});
  
            let pageTitle = this.state.pagesMeta[a].pageTitle;
  
            let aResult = { pageTitle: pageTitle };
  
            for (let b = 0; b < validationResult.length; b++) {
              let aQuestionKey = validationResult[b];
  
              //try to find the Q that failed validation
              for (let c = 0; c < aPage.components.length; c++) {
                let aComponent = aPage.components[c];
                if (aComponent.key == aQuestionKey) {
                  //found the Q
                  aResult.questionTitle =
                    aComponent.views.question.view.data.default;
                  required_list.push(aResult);
                }
              }
            }
          }
        }
  
        if (required_list.length > 0) {
          this.setState({
            pagesErrorDialog: true,
            pagesError: required_list,
          });
          return false;
        }
      }
  
      return true;
    };
  
    doCheckAllAnswers = (pageIndex) => {
        if (pageIndex === undefined) {
            pageIndex = this.props.form.current_index;
        }

        let page_data = this.props.form.request_data[pageIndex].components;
        const answer = this.props.form.answer_data;

        let required_list = [];
        for (let i in page_data) {
            let dynamicRequired = dynamicRender(this, false, page_data[i].required);
            if (page_data[i].key != "main_page") {
                const hasAnswer = answer[page_data[i].key] && 
                    answer[page_data[i].key].answer.length > 0 && 
                    answer[page_data[i].key].answer[0] != null &&
                    answer[page_data[i].key].answer[0].toString();

                // Check required fields
                if (dynamicRequired && !hasAnswer) {
                    required_list.push(page_data[i].key);
                    continue;
                }

                // Only check validation if there is an answer
                if (hasAnswer && page_data[i].validation) {
                    const passesValidation = validatePattern(
                        answer[page_data[i].key].answer[0], 
                        page_data[i].validation
                    );
                    if (!passesValidation) {
                        required_list.push(page_data[i].key);
                    }
                }
            }
        }

        return required_list;
    };
  
    doCheckTableAnswers = (pageIndex) => {
      if (pageIndex === undefined) {
        pageIndex = this.props.form.current_index;
      }
  
      let page_data = this.props.form.request_data[pageIndex].components;
  
      const answer = this.props.form.answer_data;
      console.log(answer)
  
      let required_list_table = {};
  
      for (let i in page_data) {
        if (page_data[i].key != "main_page") {
          if (page_data[i]["views"]["answer"]["view"]["format"] === "table" || page_data[i]["views"]["answer"]["view"]["format"] === "repeatable_section") {
            let cols = page_data[i]["views"]["answer"]["view"]["data"]["default"];
            let rowLabels =
              page_data[i]["views"]["answer"]["view"]["data"]["rowLabels"];
  
            let tableAns = null;

            const isRS = page_data[i]["views"]["answer"]["view"]["format"] === "repeatable_section";
  
            // If the answer exists, set it and check the data
            if (answer[page_data[i].key]["answer"]) {
              const toShow = calculateSectionsToShow(answer[page_data[i].key]);
              tableAns = parseAnswerData(
                answer[page_data[i].key],
                cols,
                isRS ? "rowsAsLists" : page_data[i]["exportType"],
                rowLabels,
                toShow,
              );
            }
  
            if (tableAns == null) continue;
  
            let listOfRequiredNotAns = this.checkAllTableRequired(
              cols,
              tableAns,
              page_data[i]["tableCells"],
              rowLabels
            );
            if (listOfRequiredNotAns.length > 0) {
              required_list_table[page_data[i].key] = listOfRequiredNotAns;
            }
          }
        }
      }
  
      return required_list_table;
    };
  
    getRowName = (rowLabels, row) => {
      if (rowLabels[row].rowLabel != "") {
        return rowLabels[row].rowLabel;
      } else {
        return (
          rowLabels[row].rowKey.charAt(0).toUpperCase() +
          rowLabels[row].rowKey.slice(1)
        );
      }
    };
  
    checkAllTableRequired = (cols, tableAns, tableCells, rowLabels) => {
      let requiredList = [];
      let transposeTable = transpose(tableAns);
  
      // Loop over columns
      for (let i = 0; i < cols.length; i++) {
        let currCol = cols[i];
  
        // If not required and table cells exist,
        // We need to check the tableCells
        if (!currCol.required) {
          if (tableCells == null) continue; // Col isn't required & table cells don't exist
  
          // Need to check each cell
          let colValues = transposeTable[i];
          for (let row = 0; row < colValues.length; row++) {
            if (tableCells[currCol.id][row].isDisabled) continue; // If disabled, don't check
            if (!tableCells[currCol.id][row].isRequired) continue;
  
            // Check the value
            if (currCol.type === "datetime") {
              if (colValues[row].value.calculatedValue === "") {
                requiredList.push(
                  `(${currCol.columnName}, ${this.getRowName(rowLabels, row)})`
                );
              }
            } else {
              if (colValues[row].calculatedValue === "") {
                requiredList.push(
                  `(${currCol.columnName}, ${this.getRowName(rowLabels, row)})`
                );
              }
            }
          }
        } else {
          // Need to check all in column are not empty
          let colValues = transposeTable[i];
  
          if (currCol.type === "datetime") {
            if (
              colValues.some((datetimeCol, row) => {
                // If tablecells exist and cell is disabled, return false
                if (tableCells != null && tableCells[currCol.id][row].isDisabled)
                  return false;
                return datetimeCol.value.calculatedValue === "";
              })
            ) {
              requiredList.push(currCol.columnName);
            }
          } else {
            if (
              colValues.some((val, row) => {
                // If tablecells exist and cell is disabled, return false
                if (tableCells != null && tableCells[currCol.id][row].isDisabled)
                  return false;
                return val.calculatedValue === "";
              })
            ) {
              requiredList.push(currCol.columnName);
            }
          }
        }
      }
  
      return requiredList;
    };
    clearDeletedFields = () => {
      //will only take care of removing deleted fields for test
      //as the components will change only for a test session
      let test_id = this.props.match.params.test_id;
      let isTest = test_id !== undefined;

      if(isTest) {
        const answer = this.props.form.answer_data;
        let components_key = [];
        //collect all the component keys for the given form
        for (let indexToSave = 0; indexToSave < Object.keys(this.props.form.request_data).length; indexToSave++) {
          let page_data = this.props.form.request_data[indexToSave].components;
          for (let componentIndex in page_data) {
            components_key.push(page_data[componentIndex].key);
          }
        }
        for (let ans in answer) {
          if (!components_key.includes(ans)) {
            delete answer[ans];
          }
        }
      }
    };

    checkAllAnswered = (pageIndex) => {
      let required_list = this.doCheckAllAnswers(pageIndex);
      let required_list_table = this.doCheckTableAnswers(pageIndex);
  
      if (
        required_list.length > 0 ||
        Object.keys(required_list_table).length > 0
      ) {
        this.setState({ required_list: required_list });
        this.setState({ required_list_table: required_list_table });
        return false;
      }
  
      return true;
    };
  
    handlePageLoad = async () => {
      let { form } = this.props;
      let page_data = form.request_data[form.current_index];
      let answer_list = "";
  
      let page_answer = {};
      Object.keys(this.state.answer).map((key) => {
        for (let i in page_data.components) {
          if (key == page_data.components[i].key) {
            answer_list = page_data.components[i].views.answer.view.data;
            answer_list = parseJson(
              dynamicRender(this, answer_list.default, answer_list.dynamic)
            );
  
            break;
          }
        }
  
        page_answer[key] = answerDecoder(this.state.answer[key], answer_list);
      });
    };

    handleOpenAnnotation = async () => {
      const { isThreadSidebarOpen, isAnnotationModeOn } = this.state;
      const { session_id: sessionID } = this.props.match.params;
  
      if (isAnnotationModeOn) {
          this.setState({ isAnnotationModeOn: false });
          removeAnnotationHighlights();
      } else {
          await this.handleSaveProgress(false);
  
          this.setState({
              isAnnotationModeOn: true
          });
  
          if (!isThreadSidebarOpen) {
              this.setState({ isThreadSidebarOpen: true });
          }
  
          this.addAnnotationHighlights(sessionID);
      }
  };

  
    handleOpenSidebar = () => {
      this.setState({ isThreadSidebarOpen: !this.state.isThreadSidebarOpen, highlightedAnnotation: null });
    }
  
    handleNext = async (event, pageIndex) => {
      this.setState({
        sended: true,
      });
      // this.props.dispatchFormLoading();
      if (this.isAuthenticated() === false) {
        this.setState({ authState: "signIn", sended: false });
      } else {
        if (this.checkAllAnswered() == true) {
          let { form } = this.props;
          let password = this.state.password || "";
          let form_id = this.state.form_id;
  
          if (pageIndex === undefined) {
            pageIndex = (parseInt(form.current_index) + 1).toString();
          }
          this.props.dispatchFormLoading();
          const promise = new Promise(this.handlePageLoad).then(
            // await this.handleSaveWhenNextOrBack(),
            await this.props.dispatchfetchNextPage(
              password,
              form_id,
              pageIndex,
              this.state.projectVersion,
              this.state.projectSessionId,
              this.state.threadId
            ),
            this.setState({
              current_index: pageIndex,
              isReady: true,
              password_alert: false,
              loading: false,
              sended: false,
            }),
            (document.documentElement.scrollTop = 0)
          );
  
          this.visitedPages.add(parseInt(pageIndex));
          if (this.state.isAnnotationModeOn) this.addAnnotationHighlights(this.props.match.params.session_id);
        } else {
          document.documentElement.scrollTop = 0;
          this.setState({
            sended: false,
          });
        }
      }
    };
  
    handleBack = async () => {
      this.setState({
        sended: true,
      });
      this.props.dispatchFormLoading();
      await this.props.dispatchgoBackToPrevious();
      //this.handleSaveWhenNextOrBack();
      document.documentElement.scrollTop = 0;
  
      this.setState({
        sended: false,
      });
  
      if (this.state.isAnnotationModeOn) this.addAnnotationHighlights(this.props.match.params.session_id);
    };
  
    handleToHome = () => {
      window.history.pushState({}, document.title, "/");
      window.location.pathname = "/";
    };
  
    setStateAsync(state) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  
    handleRedo = () => {
      if (this.isAuthenticated() === false) {
        this.setState({ authState: "signIn" });
      } else {
        this.setState({
          sended: false,
          response: false,
          finished: false,
          processD: false,
        });
      }
    };
  
    getDisplayName = (data) => {
      if (data) {
        if (data.project_name) {
          //we found the project name
          return data.project_name.answer[0];
        } else if (data.site_name) {
          return data.site_name.answer[0];
        } else if (data.Q3 && data.Q3.answer[0]) {
          //this is the last resort to handle the old forms
          return data.Q3.answer[0];
        }
      }
    };
  
    saveUsageService = async (e) => {
      if (!(config.USAGE_ADDRESS && config.USAGE_ADDRESS != "")) {
        //no usage. do nothing
        return;
      }
  
      const tokenId = await this.processIdToken();
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: tokenId,
      };
      let data = {
        formId: this.props.match.params.form_id,
        sessionId: this.props.match.params.session_id,
        status: "PROCESS",
        // Removed usageType
        displayName: this.getDisplayName(this.props.form.answer_data),
      };
  
      const json = await axios
        .post(config.USAGE_ADDRESS + "/usage", data, {
          headers: headers,
        })
        .catch((e) => {
          console.log(e.response.data.msg);
        });
    };
  
    postComment = async (keyComponent, comment, notificationEmails) => {
      this.saveUsageService();
      const { answer_data } = this.props.form;
  
      this.setState({ pageProcessing: true });
  
      let failedUploads = {};
      const data = await this.traverseAndFlatten(answer_data, "SAVE", null, failedUploads);

      const saveData = data ? { ...data } : {};

      // Remove any failed uploads from the data
      if (failedUploads && Object.keys(failedUploads).length > 0) {
        Object.keys(failedUploads).map((key) => {
          if (key in saveData) delete saveData[key];
        });
      }
  
      let { session_id, form_id, test_id } = this.props.match.params;
      let requestData;
      const isTest = test_id !== undefined;
      let sessionURL;
  
      if (isTest) {
        sessionURL = `${config.API_ADDRESS}/test_form_session?session_id=${session_id}&form_id=${form_id}`;
      } else {
        sessionURL = `${config.API_ADDRESS}/form_session?session_id=${session_id}&form_id=${form_id}`;
      }
  
      if (!session_id || session_id.trim() == "") {
        //no session ID, so we need to create one here
        session_id = uuid();
  
        requestData = {
          session_id,
          answer_key: keyComponent,
          comment,
          notificationEmails: notificationEmails,
          formData: {
            formName: this.state.form_title,
            formUrl: `https://form.workflow86.com/form/${this.state.form_id}/${session_id}`,
          },
          isTest,
        };
  
        const requestOne = axios(sessionURL, {
          method: "post",
          data: {
            data: saveData,
            session_id: session_id,
            form_id: this.props.match.params.form_id,
            password: this.state.password,
            projectSessionId: this.state.projectSessionId,
            projectVersion: this.state.projectVersion,
            isSingleUse: this.state.isSingleUse,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const requestTwo = axios(`${config.API_ADDRESS}/post_comment`, {
          method: "post",
          data: requestData,
        });
  
        axios.all([requestOne, requestTwo]).then((res) => {
          this.setState({ pageProcessing: false });
          let formSessionUrl = `/form/${this.props.match.params.form_id}/${session_id}`;
          if (isTest) {
            formSessionUrl = `/form/form_test/${this.props.match.params.form_id}/${test_id}/${session_id}`;
          }
          this.props.history.push(formSessionUrl);
        });
      } else {
        requestData = {
          session_id,
          answer_key: keyComponent,
          comment,
          notificationEmails: notificationEmails,
          formData: {
            formName: this.state.form_title,
            formUrl: `https://form.workflow86.com/form/${this.state.form_id}/${session_id}`,
          },
          isTest,
        };
  
        axios(`${config.API_ADDRESS}/post_comment`, {
          method: "post",
          data: requestData,
        }).then(() => {
          this.setState({ pageProcessing: false });
        });
      }
    };
  
    handleSaveProgress = async (showSaveDialog) => {
      if (this.state.pageProcessing) return; // If already saving, return
      this.clearDeletedFields();
      this.saveUsageService();
  
      const { answer_data } = this.props.form;
  
      this.setState({ pageProcessing: true });
      
      try {
        let { session_id, form_id, test_id } = this.props.match.params;
  
        if (!session_id || session_id.trim() == "") {
          //no session ID, so we need to create one here
          this.props.match.params.session_id = uuid();
          session_id = this.props.match.params.session_id;
        }

        let failedUploads = {}; // Define a list of keys that failed to upload
        let data = await this.traverseAndFlatten(answer_data, "SAVE", null, failedUploads);

        const saveData = data ? { ...data } : {}; // Make a copy of the data

        if (failedUploads && Object.keys(failedUploads).length > 0) {
          Object.keys(failedUploads).map((key) => {
            if (key in saveData) delete saveData[key];
          });
        }
  
        let sessionURL = `${config.API_ADDRESS}/form_session?session_id=${session_id}&form_id=${form_id}`;
  
        if (test_id !== undefined) {
          sessionURL = `${config.API_ADDRESS}/test_form_session?session_id=${session_id}&form_id=${form_id}`;
        }

        return this.makeFormSaveRequest(sessionURL, saveData, session_id)
          .then((res) => {
            this.setState({
              ...(showSaveDialog && { saveDialog: true, formFailures: { failedUploads } }),
              pageProcessing: false,
              lastSaved: new Date().getTime(),
            });
            let formSessionURL = `/form/${this.props.match.params.form_id}/${session_id}`;
  
            if (test_id !== undefined) {
              formSessionURL = `/form/form_test/${this.props.match.params.form_id}/${test_id}/${session_id}`;
            }
  
            this.props.history.push(formSessionURL);
          })
          .catch((e) => {
            this.setState({ saveDialog: true, formFailures: { failedUploads, failedSave: true } });
            this.handleErrorState(e);
            console.log(e);
          });
      } catch (e) {
        this.handleErrorState(e);
      }
    };

    makeFormSaveRequest = async (sessionURL, data, session_id) => {
      return axios(sessionURL, {
        method: "post",
        data: {
          data: data,
          session_id: session_id,
          form_id: this.props.match.params.form_id,
          password: this.state.password,
          projectSessionId: this.state.projectSessionId,
          projectVersion: this.state.projectVersion,
          isSingleUse: this.state.isSingleUse,
          component_id: this.props.match.params.form_id,
          test_id: this.props.match.params.test_id,
          submissionId: this.state.submissionId
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
  
    handleSaveWhenNextOrBack = async () => {
      this.saveUsageService();
      const { session_id, form_id } = this.props.match.params;
      const { answer_data, projectSessionId } = this.props.form;
      const { password } = this.state;
  
      this.setState({ pageProcessing: true });
  
      const data = await this.traverseAndFlatten(answer_data, "SAVE");
      const url = `${config.API_ADDRESS}/form_session?session_id=${session_id}&form_id=${form_id}`;
      axios(url, {
        method: "post",
        data: {
          data: data,
          session_id,
          form_id,
          password,
          projectSessionId: this.state.projectSessionId,
          projectVersion: this.state.projectVersion,
          isSingleUse: this.state.isSingleUse,
          submissionId: this.state.submissionId,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((e) => {
          this.setState({ pageProcessing: false });
        })
        .catch((e) => {
          alert(e.response.data.msg);
        });
    };
  
    isContain = (data, container) => {
      function hasObject(element, target) {
        return _.isEqual(element, target);
      }
  
      let i = undefined;
  
      container.forEach((n) => {
        let a = hasObject(n, data);
        if (a == true) {
          i = n;
        }
  
        if (Array.isArray(n)) {
          if (data == n[0]) {
            i = n;
          }
        }
      });
      return i;
    };
  
    closeAlert = () => {
      this.setState({ AlertD: false });
    };
  
    getRelativeSaveText = () => {
      let relativeTime = moment(this.state.lastSaved).fromNow();
  
      if (relativeTime === "Invalid date") return null; 
      else return <div id={"last-saved-text"}><AutoSaveIcon /> {`Last saved ${relativeTime}`}</div>
    }
  
  
    renderBreadCrums = () => {
      const view = [];
      const data = this.props.form.request_data;
      const current_index = this.props.form.current_index;
  
      if (this.props.form.current_index <= 3) {
        for (let i = 0; i <= this.props.form.current_index; i++) {
          view.push(
            <div className="renderBreadCrumsDiv">
              <p
                onClick={() => this.handleToPage(i)}
                className="renderBreadCrumsPtag"
              >
                {data[i].title.view.data.default}
              </p>
              {data[i].title.view.data.default != undefined &&
              data[i].title.view.data.default.length != 0 &&
              i != this.props.form.current_index ? (
                <div>&nbsp; | &nbsp;</div>
              ) : (
                ""
              )}
            </div>
          );
        }
      } else {
        view.push(
          <div className="renderBreadCrumsDivElse">
            &nbsp;
            <p
              onClick={() => this.handleToPage(0)}
              className="renderBreadCrumsPtag"
            >
              {data[0].title.view.data.default}
            </p>
            &nbsp; /
          </div>
        );
        view.push(
          <div className="renderBreadCrumsDivElse">
            &nbsp;
            <p
              onClick={() => this.handleToPage(1)}
              className="renderBreadCrumsPtag"
            >
              {data[1].title.view.data.default}
            </p>
            &nbsp; /
          </div>
        );
        view.push(
          <div className="renderBreadCrumsDivElse">
            &nbsp;
            <p
              style={{
                maxWidth: 60,
                margin: 0,
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              ...
            </p>
            &nbsp; /
          </div>
        );
        view.push(
          <div className="renderBreadCrumsDivElse">
            &nbsp;
            <p
              onClick={() => this.handleToPage(this.props.form.current_index - 1)}
              className="renderBreadCrumsPtag"
            >
              {data[this.props.form.current_index - 1].title.view.data.default}
            </p>
            &nbsp; /
          </div>
        );
        view.push(
          <div className="renderBreadCrumsDivElse">
            &nbsp;
            <p
              onClick={() => this.handleToPage(this.props.form.current_index)}
              className="renderBreadCrumsPtag"
            >
              {data[this.props.form.current_index].title.view.data.default}
            </p>
            &nbsp; /
          </div>
        );
      }
  
      return view;
    };
  
    urlPreviewLink(title) {
      return title + " | Workflow86";
    }
  
    render() {
      const { classes, form } = this.props;
      const { test_id } = this.props.match.params;
      const { logoSrc, coverImage } = this.state;
      const isTest = test_id !== undefined;
      const readOnly = this.state.canReadOnly && !isTest;
  
      if (this.state.receiptState !== "hidden") {
        return (
          <Grid container direction="row" justify="center" className="gridStyle">
            <SubmissionReceipt
              submissionState={this.state.receiptState}
              formId={this.props.match.params.form_id}
              sessionId={this.props.match.params.session_id}
              testId={this.props.match.params.test_id}
              isSingleUse={this.state.isSingleUse}
              submMsg={this.state.submMsg}
              canAddSubmission={this.state.canAddSubmission}
              canEditSubmission={this.state.canEditSubmission}
              canReadOnly={this.state.canReadOnly}
              formType={this.state.formType}
              onBack={() => {
                this.setState({ receiptState: "hidden", response: false });
              }}
            />
          </Grid>
        );
      }
  
      try {
        if (
          this.state.authState !== "signedIn" &&
          this.state.form_oauth2 !== undefined &&
          this.state.form_oauth2 !== "undefined" &&
          this.state.form_oauth2 !== "" &&
          this.state.form_oauth2 !== null
        ) {
          return (
            <Authenticator hideDefault={true}>
              <CustomSignIn
                authState={this.state.authState}
                updateUsername={this.updateUsername}
              />
            </Authenticator>
          );
        } else {
          if (form.isReady == true) {
            const page_data = form.request_data[form.current_index];
            let Title_component = this.componentSelector(
              page_data.title.view.format,
              {
                data: dynamicRender(
                  this,
                  page_data.title.view.data.default,
                  page_data.title.view.data.dynamic
                ),
                style: page_data.title.view.style,
              },
              "title",
              form.current_index
            );
  
            const title = page_data.title.view.data.default;
            const urlPreview = this.urlPreviewLink(title);
  
            return (
              <>
                {this.state.isThreadSidebarOpen && (
                  <ThreadSidebar
                    isOpen={this.state.isThreadSidebarOpen}
                    username={this.state.username}
                    timezone={this.state.timezone}
                    showSidebarAndHighlight={this.showSidebarAndHighlight}
                    formId={this.state.form_id}
                    formSessionId={this.props.match.params.session_id}
                    highlightedAnnotation={this.state.highlightedAnnotation}
                    props={this.props}
                    traverseAndFlatten={this.traverseAndFlatten}
                    password={this.state.password}
                    projectSessionId={this.state.projectSessionId}
                    projectVersion={this.state.projectVersion}
                    isSingleUse={this.state.isSingleUse}
                    rerenderSidebar={this.state.rerenderSidebar}
                    addAnnotationHighlights={this.addAnnotationHighlights}
                    annotationCount={this.state.annotationCount}
                    updateState={(obj) => this.setState(obj)}
                  />
                )}

                {this.state.isAnnotationModeOn && (
                  <>
                    <div className="annotation-mode-active" />
                    <div className="annotation-mode-banner">
                      Comment mode on: all answers are read only
                    </div>
                  </>
                )}

                {this.state.canAnnotateContent &&
                  !this.state.previewMode &&
                  !this.state.canReadOnly &&
                  !this.state.isTest && (
                    <Hidden smDown>
                      <StyledBadge
                        badgeContent={this.state.annotationCount}
                        color="secondary"
                        isOpen={this.state.isThreadSidebarOpen}
                        highlightedAnnotation={this.state.highlightedAnnotation}
                        classes={{ badge: classes.annotationBadge }}
                        annotationCount={this.state.annotationCount}
                        onClick={this.handleOpenSidebar}
                        id="form-open-sidebar-button"
                      >
                        <AutoSaveTooltip
                          title={
                            <TooltipText>
                              {this.state.isThreadSidebarOpen
                                ? "Close annotation sidebar"
                                : "View annotation sidebar"}
                            </TooltipText>
                          }
                          placement={"left"}
                        >
                          <AnnotateAvatar
                            isOpen={this.state.isThreadSidebarOpen}
                            highlightedAnnotation={
                              this.state.highlightedAnnotation
                            }
                            annotationCount={this.state.annotationCount}
                          >
                            <AnnotateSideIcon
                              color={
                                this.state.isThreadSidebarOpen
                                  ? "#FFFFFF"
                                  : "#000000"
                              }
                            />
                          </AnnotateAvatar>
                        </AutoSaveTooltip>
                      </StyledBadge>
                    </Hidden>
                  )}
                <Helmet>
                  <title>
                    {this.state.form_title ? this.state.form_title : "Untitled"}{" "}
                    | Workflow86
                  </title>
                </Helmet>
                <AppBar
                  position="sticky"
                  color="white"
                  className="app-bar"
                >
                {readOnly && !this.state.isTest && (
                  <>
                    <div className={`annotation-mode-active ${this.state.isTerminated ? 'terminated' : 'readonly'}`} />
                    <div className={`annotation-mode-banner ${this.state.isTerminated ? 'terminated' : 'readonly'}`}>
                      {this.state.isTerminated 
                        ? "This form has been terminated and cannot be submitted"
                        : "This form has already been submitted and is read only"
                      }
                    </div>
                  </>
                )}

                {!readOnly && !this.state.isTest && this.state.isTerminated && (
                  <>
                    <div className="annotation-mode-active terminated" />
                    <div className="annotation-mode-banner terminated">
                      This form has been terminated and cannot be submitted
                    </div>
                  </>
                )}
                  <Toolbar variant="dense" className="form-toolbar">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={8} md={8}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justify="flex-start"
                        >
                          <Grid
                            item
                            className="form-logo-container"
                          >
                            <Grid className="form-logo-wrapper">
                              {this.state.form_logo == "DELETE" ||
                              this.state.form_logo == "" ? (
                                <img
                                  alt="logo"
                                  className="form-logo imageForm"
                                  src={"/images/default-image-form.png"}
                                />
                              ) : (
                                <img
                                  alt="logo"
                                  className="form-logo imageForm"
                                  src={this.state.form_logo}
                                />
                              )}
                            </Grid>
                            <Grid className="form-title-container">
                              {this.state.form_title}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <Grid
                          container
                          item
                          direction="row"
                          alignItems="center"
                          justify="flex-end"
                        >
                          <Hidden smDown>
                            {this.state.hasAutoSaveEnabled && (
                              <Grid className="autosaveGrid">
                                <AutoSaveTooltip
                                  title={
                                    <TooltipText>
                                      {"👍 Your work is auto-saved"}
                                    </TooltipText>
                                  }
                                >
                                  <StyledSaveIcon />
                                </AutoSaveTooltip>
                              </Grid>
                            )}
                            {this.state.canAnnotateContent && (
                              <Grid item>
                                <AutoSaveTooltip
                                  title={
                                    <TooltipText>
                                      {this.state.isAnnotationModeOn
                                        ? "Turn off annotation mode"
                                        : "Turn on annotation mode"}
                                    </TooltipText>
                                  }
                                >
                                  <AnnotateButton
                                    startIcon={<AnnotateIcon />}
                                    onClick={this.handleOpenAnnotation}
                                    isAnnotationModeOn={
                                      this.state.isAnnotationModeOn
                                    }
                                    id={"form-annotate-button"}
                                    disabled={
                                      this.state.previewMode ||
                                      this.state.canReadOnly ||
                                      this.state.isTest
                                    }
                                  >
                                    Comment
                                  </AnnotateButton>
                                </AutoSaveTooltip>
                              </Grid>
                            )}
                            <Grid item>
                              {((!this.state.sended &&
                                this.state.canSaveSubmission) ||
                                this.state.isTest) && (
                                <Button
                                  variant="contained" 
                                  color="primary"
                                  onClick={() => this.handleSaveProgress(true)}
                                  disabled={
                                    (this.state.pageProcessing ||
                                    !this.state.canSaveSubmission ||
                                    this.state.previewMode ||
                                    readOnly) &&
                                    !this.state.isTest
                                  }
                                  startIcon={<SaveIcon />}
                                >
                                  {this.state.isTest ? "Save Test Values" : "Save"}
                                  {this.state.pageProcessing && (
                                    <CircularProgress
                                      className="buttonProgress"
                                      size={20}
                                    />
                                  )}
                                </Button>
                              )}
                            </Grid>
                            <Grid item>
                              <UserAccount />
                            </Grid>
                          </Hidden>

                          <Hidden mdUp>
                            <Grid item className="menu-icon-button">
                              <IconButton edge="start" aria-label="menu">
                                <MenuIcon
                                  onClick={(event) => {
                                    this.setState({
                                      openMainMenu: true,
                                      anchorEl: event.currentTarget,
                                    });
                                  }}
                                />
                                <Menu
                                  open={this.state.openMainMenu}
                                  anchorEl={this.state.anchorEl}
                                  onClose={() => {
                                    this.setState({
                                      openMainMenu: false,
                                      anchorEl: null,
                                    });
                                  }}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  {this.state.canAnnotateContent && (
                                    <MenuItem>
                                      <AnnotateButton
                                        startIcon={<AnnotateIcon />}
                                        onClick={this.handleOpenAnnotation}
                                        isAnnotationModeOn={
                                          this.state.isAnnotationModeOn
                                        }
                                        id={"form-annotate-button"}
                                        disabled={
                                          this.state.previewMode ||
                                          this.state.canReadOnly ||
                                          this.state.isTest
                                        }
                                      >
                                        Comment
                                      </AnnotateButton>
                                    </MenuItem>
                                  )}
                                  {this.state.canAnnotateContent && (
                                    <MenuItem>
                                      <ShowAnnotateButton
                                        startIcon={<Chat />}
                                        onClick={this.handleOpenSidebar}
                                        isThreadSidebarOpen={
                                          this.state.isThreadSidebarOpen
                                        }
                                        id={"show-annotate-button"}
                                        disabled={
                                          this.state.previewMode ||
                                          this.state.canReadOnly ||
                                          this.state.isTest
                                        }
                                      >
                                        {this.state.isThreadSidebarOpen
                                          ? "Hide comments"
                                          : "Show comments"}
                                      </ShowAnnotateButton>
                                    </MenuItem>
                                  )}
                                  {((!this.state.sended &&
                                    this.state.canSaveSubmission) ||
                                    this.state.isTest) && (
                                    <MenuItem>
                                      {!this.state.sended && (
                                        <Button
                                          variant="contained" 
                                          color="primary"
                                          onClick={() => this.handleSaveProgress(true)}
                                          disabled={
                                            (this.state.pageProcessing ||
                                              !this.state.canSaveSubmission ||
                                              this.state.previewMode ||
                                              readOnly) &&
                                            !this.state.isTest
                                          }
                                          startIcon={<SaveIcon />}
                                        >
                                          {this.state.isTest ? "Save Test Values" : "Save"}
                                          {this.state.pageProcessing && (
                                            <CircularProgress
                                              className="buttonProgress"
                                              size={20}
                                            />
                                          )}
                                        </Button>
                                      )}
                                    </MenuItem>
                                  )}
                                  <MenuItem>
                                    <UserAccount />
                                  </MenuItem>

                                  {this.state.pagesMeta.map((aPage, index) => {
                                    let currentIndex = parseInt(
                                      form.current_index
                                    );

                                    if (
                                      this.visitedPages.has(index) ||
                                      currentIndex + 1 == index
                                    ) {
                                      return (
                                        <MenuItem
                                          className={index == currentIndex ? "menu-item-current" : ""}
                                          onClick={(event) => {
                                            this.handleNext(event, index);
                                          }}
                                        >
                                          {aPage.pageTitle}
                                        </MenuItem>
                                      );
                                    } else {
                                      return (
                                        <MenuItem
                                          className={index == currentIndex ? "menu-item-current" : ""}
                                        >
                                          {aPage.pageTitle}
                                        </MenuItem>
                                      );
                                    }
                                  })}
                                </Menu>
                              </IconButton>
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>

                <Grid>
                  {coverImage !== undefined &&
                  coverImage !== "" &&
                  coverImage.uploaded.id !== undefined ? (
                    <div className="form-cover-image">
                      <CoverImage coverImage={coverImage} />
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>

                <Popover
                  showPopover={
                    this.state.canAnnotateContent &&
                    !this.state.previewMode &&
                    !this.state.canReadOnly &&
                    !this.state.isTest
                  }
                  openSidebar={() => {
                    this.setState({
                      isThreadSidebarOpen: true,
                      rerenderSidebar: uuid(),
                      highlightedAnnotation: null,
                    });
                  }}
                  isAnnotationModeOn={this.state.isAnnotationModeOn}
                  openWithoutReplyBox={() => {
                    this.handleOpenAnnotation();
                    let sel = window.getSelection
                      ? window.getSelection()
                      : document.selection;
                    if (!sel) return;
                    if (sel.removeAllRanges) sel.removeAllRanges();
                    else if (sel.empty) sel.empty();
                  }}
                  children={
                    <Grid container spacing={1} className={classes.layout}>
                      {/* <Hidden smDown>
                        <Grid item xs={0} md={1}></Grid>
                      </Hidden> */}
                      <NavigationMenu 
                        pagesMeta={this.state.pagesMeta}
                        visitedPages={this.visitedPages}
                        handleNext={this.handleNext}
                        form={this.props.form}
                        parsePlaceholder={parsePlaceholder}
                        processIdToken={this.processIdToken}
                        projectSessionId={this.state.projectSessionId ? this.state.projectSessionId : this.state.submissionId}
                        formId={this.props.match.params.form_id}
                        formTitle={this.state.form_title}
                        handleSaveProgress={this.handleSaveProgress}
                        isSaving={this.state.pageProcessing}
                        showResubmitDialog={this.state.showResubmitDialog}
                        closeResubmitDialog={() => { this.setState({ showResubmitDialog: false }); }}
                        handleSubmit={this.handleSubmit}
                        timezone={this.state.timezone}
                        threadId={this.state.threadId}
                        readOnly={readOnly}
                      />
                      <Grid item xs={12} md={8}>
                        <AlertMsg
                          open={this.state.AlertD}
                          handleClose={this.closeAlert}
                          msg={this.state.AlertMsg}
                          status={this.state.AlertStatus}
                        />
                        <Paper
                          className={classes.paper}
                        >
                          <SaveDialog
                            open={this.state.saveDialog}
                            close={() => {
                              this.setState({ saveDialog: false, formFailures: {} });
                            }}
                            formFailures={this.state.formFailures}
                          />

                          {
                            <div className="descriptionDiv">
                              {form.loading && (
                                <div>
                                  <CircularProgress size={50} />
                                </div>
                              )}
                              <FadeIn>
                                {!form.loading && (
                                  <div
                                    className="descriptionTitle"
                                    id="title-area"
                                  >
                                    {Title_component}
                                    {
                                      <p style={{ marginTop: 8 }}>
                                        {dynamicRender(
                                          this,
                                          page_data.title.description.default,
                                          page_data.title.description.dynamic
                                        )}
                                      </p>
                                    }
                                  </div>
                                )}

                                {!form.loading && //go through all form questions
                                  page_data.components.map((n) => {
                                    let auto; //to handle prefills

                                    let answer_list = parseJson(
                                      dynamicRender(
                                        this,
                                        n.views.answer.view.data.default,
                                        n.views.answer.view.data.dynamic
                                      )
                                    );
                                    let question_list = parseJson(
                                      dynamicRender(
                                        this,
                                        n.views.question.view.data.default,
                                        n.views.question.view.data.dynamic
                                      )
                                    );

                                    //here are the conditionals
                                    //this is a work around to create its default value
                                    createDefaultCondition(
                                      n.visibility,
                                      this.props.form
                                    );

                                    let visible =
                                      dynamicRender(this, "", n.visibility) +
                                      "";

                                    auto = dynamicRender(this, "", n.auto);

                                    if (auto != "") {
                                      if (
                                        n.views.answer.view.format ==
                                          "multiple_choice" ||
                                        n.views.answer.view.format ==
                                          "single_choice" ||
                                        n.views.answer.view.format ==
                                          "single_select"
                                      ) {
                                        let answer = this.isContain(
                                          auto,
                                          answer_list
                                        );
                                        if (!answer) {
                                          visible = "false";
                                        } else {
                                          auto = answer;
                                        }
                                      } else if (
                                        typeof answer_list != "object"
                                      ) {
                                        answer_list = auto;
                                      }
                                    }

                                    let readonly =
                                      dynamicRender(
                                        this,
                                        "false",
                                        n.read_only
                                      ) + "";

                                    return (
                                      <div
                                        style={{
                                          display:
                                            visible == "false"
                                              ? "none"
                                              : visible == ""
                                              ? "none"
                                              : "",
                                        }}
                                      >
                                        {this.state.required_list_table[
                                          n.key
                                        ] != null &&
                                          this.state.required_list_table[
                                            n.key
                                          ].map((colName) => (
                                            <div className="descriptionImageDiv">
                                              <i className="material-icons descriptionImage">
                                                error
                                              </i>
                                              <p className={"error"}>
                                                {colName} is a required field
                                              </p>
                                            </div>
                                          ))}
                                        {this.state.required_list.lastIndexOf(
                                          n.key
                                        ) != -1 && (
                                          <div className="descriptionImageDiv">
                                            <i className="material-icons descriptionImage">
                                              error
                                            </i>
                                            <p className={"error"}>
                                              This question is required
                                            </p>
                                          </div>
                                        )}
                                        {this.state.required_list.lastIndexOf(
                                          n.key
                                        ) === 0 &&
                                        this.state.required_list.pop() ? (
                                          <RequiredQuestionAlert
                                            open={this.handleOpen}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {n.views.answer.view.format !=
                                        "row_text" ? (
                                          <div
                                            className="descriptionQuestionDiv"
                                            id="question-area"
                                          >
                                            {this.componentSelector(
                                              n.views.question.view.format,
                                              {
                                                data:
                                                  question_list +
                                                  // adding dynamic render here to show * if field is required or not to avoid confusion
                                                  `${
                                                    dynamicRender(
                                                      this,
                                                      "false",
                                                      n.required
                                                    )
                                                      ? " * "
                                                      : ""
                                                  }`,
                                                style: parseJson(
                                                  n.views.question.style
                                                ),
                                              },
                                              "question",
                                              n.key
                                            )}

                                            <p
                                              id={`${n.key}-question-description`}
                                              className="descriptionQuestionPtag"
                                            >
                                              {parsePlaceholder(
                                                dynamicRender(
                                                  this,
                                                  n.views.question.description
                                                    .default,
                                                  n.views.question.description
                                                    .dynamic
                                                ),
                                                this.props.form
                                              )}
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <div
                                          className="descriptionAnswerDiv"
                                          id="answer-area"
                                        >
                                          <div>
                                            {" "}
                                            {this.componentSelector(
                                              n.views.answer.view.format,
                                              {
                                                data: answer_list,
                                                style: parseJson(
                                                  n.views.answer.style
                                                ),
                                                key: n.key,
                                                comment: n.comment,
                                                list_min: n.list_min,
                                                list_max: n.list_max,
                                                file_type: n.file_type,
                                                question_text: question_list,
                                                drop_down: n.drop_down,
                                                outputFormat: n.outputFormat,
                                                timezone: n.timezone,
                                                numRows: n.numRows,
                                                exportType: n.exportType,
                                                tableCells: n.tableCells,
                                                validation: n.validation,
                                                ...(n.views.answer.view.data
                                                  .rowLabels && {
                                                  rowLabels:
                                                    n.views.answer.view.data
                                                      .rowLabels,
                                                }),
                                                ...n,
                                                auto: auto,
                                                read_only:
                                                  readOnly == true
                                                    ? true
                                                    : JSON.parse(readonly),
                                              },
                                              "answer",
                                              n.key
                                            )}
                                            <div
                                              style={{
                                                display: n.comment
                                                  ? "inline-block"
                                                  : "none",
                                                flexDirection: "column",
                                                width: "100%",
                                                marginTop: "1rem",
                                              }}
                                            >
                                              <h6
                                                style={{ marginBottom: "1rem" }}
                                              >
                                                Comments
                                              </h6>
                                              {/** This comment is to handle legacy */}
                                              {this.props.form.answer_data[
                                                n.key
                                              ] !== undefined &&
                                                this.props.form.answer_data[
                                                  n.key
                                                ].comment && (
                                                  <CommentBubble
                                                    legacy
                                                    comment={
                                                      this.props.form
                                                        .answer_data[n.key]
                                                        .comment
                                                    }
                                                  />
                                                )}
                                              {this.props.form.comments[
                                                n.key
                                              ] !== undefined &&
                                                this.props.form.comments[
                                                  n.key
                                                ].map(
                                                  ({ comment, user, date }) => (
                                                    <CommentBubble
                                                      comment={comment}
                                                      user={user}
                                                      date={date}
                                                      timezone={
                                                        this.state.timezone
                                                      }
                                                    />
                                                  )
                                                )}
                                              <Comment
                                                keyComponent={n.key}
                                                postComment={this.postComment}
                                                notificationEmails={
                                                  n.notificationEmails
                                                }
                                                username={this.state.username}
                                                disabled={
                                                  this.state.sended ||
                                                  this.state.pageProcessing ||
                                                  readOnly ||
                                                  this.state.previewMode
                                                }
                                              />
                                            </div>
                                            <p className="descriptionAnswerPtag">
                                              {dynamicRender(
                                                this,
                                                n.views.answer.description
                                                  .default,
                                                n.views.answer.description
                                                  .dynamic
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                {form.current_index == form.form_length - 1}
                                <div>
                                  {form.current_index >=
                                    form.form_length - 1 && (
                                    <div style={{ marginTop: 24 }}>
                                      {readOnly == true &&
                                      !this.state.isTest ? (
                                        <Grid
                                          container
                                          item
                                          xs={12}
                                          direction={"row"}
                                          justify={"center"}
                                          alignItems={"center"}
                                        >
                                        </Grid>
                                      ) : this.state.canSubmitSubmission ? (
                                        <SubmitButtons 
                                          {...this.state} 
                                          form={form} 
                                          classes={classes} 
                                          readOnly={readOnly} 
                                          handleSubmit={this.handleSubmit}
                                          openResubmitDialog={() => this.setState({ showResubmitDialog: true })}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <div
                                    style={{ height: "50px", paddingTop: 24 }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        display:
                                          form.current_index === 0 ||
                                          this.state.sended ||
                                          this.state.pageProcessing
                                            ? "none"
                                            : "initial",
                                      }}
                                    >
                                      <Button
                                        disabled={
                                          form.current_index === 0 ||
                                          this.state.sended ||
                                          this.state.pageProcessing
                                        }
                                        onClick={this.handleBack}
                                        color="primary"
                                        variant="outlined"
                                      >
                                        Back{" "}
                                        {this.state.pageProcessing && (
                                          <CircularProgress
                                            className="buttonProgress"
                                            size={20}
                                          />
                                        )}
                                      </Button>
                                    </div>
                                    <div style={{ float: "right" }}>
                                      {this.state.sended == true &&
                                      !this.state.isSubmitting ? (
                                        <div>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={this.handleToHome}
                                          >
                                            Search for a form
                                          </Button>
                                          <br />
                                          <br />
                                        </div>
                                      ) : form.current_index >=
                                        form.form_length - 1 ? (
                                        ""
                                      ) : (
                                        <Button
                                          color="primary"
                                          variant="outlined"
                                          disabled={
                                            form.current_index >=
                                              form.form_length - 1 ||
                                            this.state.pageProcessing
                                          }
                                          onClick={this.handleNext}
                                        >
                                          Next{" "}
                                          {this.state.pageProcessing && (
                                            <CircularProgress
                                              className="buttonProgress"
                                              size={20}
                                            />
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </FadeIn>
                            </div>
                          }

                          <AutosaveFooter>
                            <FooterText>
                              {this.getRelativeSaveText()}
                            </FooterText>
                          </AutosaveFooter>

                          <div className="form-footer" style={{}}>
                            <span style={{ display: "flex" }}>
                              Version {this.state.projectVersion} |
                              <Tooltip
                                title={format(
                                  this.state.lastModified,
                                  this.state.timezone
                                )}
                              >
                                <Typography
                                  component="p"
                                  className="textAuthor"
                                  id="last-updated-text"
                                >
                                  {" "}
                                  Updated{" "}
                                  {format(
                                    this.state.lastModified,
                                    this.state.timezone,
                                    "relative"
                                  )}
                                  {this.state.branding && "|"}
                                </Typography>
                              </Tooltip>
                            </span>
                            {this.state.branding === true && (
                              <span
                                style={{ display: "flex" }}
                                id="powered-by-workflow86-text"
                              >
                                Powered{" "}
                                <FlashOnIcon
                                  style={{ fontSize: "15px", color: "#ffee00" }}
                                />{" "}
                                by{" "}
                                <a
                                  target="_blank"
                                  href={"https://www.workflow86.com"}
                                  style={{ marginLeft: 4 }}
                                >
                                  Workflow86
                                </a>
                              </span>
                            )}
                          </div>
                        </Paper>
                        {/* {(this.state.projectSessionId === null ||
                      this.state.projectSessionId === undefined) &&
                      !this.state.sended &&
                      this.state.testResult !== null && (
                        <TestResult
                          testResult={this.state.testResult}
                          testId={this.props.match.params.test_id}
                        />
                      )} */}
                      </Grid>
                    </Grid>
                  }
                />
                <div
                  style={{
                    bottom: 0,
                    width: "100%",
                    zIndex: 1000000,
                    display: this.state.previewMode ? "block" : "none",
                  }}
                  className={classes.footerPreview}
                >
                  <p style={{ margin: 0, fontSize: "18px" }}>
                    This is a form preview and cannot be submitted
                  </p>
                </div>

                {this.state.showFailedSubmissionDialog && (
                  <FailedSubmissionDialog 
                    formFailures={this.state.formFailures}
                    close={() => this.setState({ showFailedSubmissionDialog: false, formFailures: {} })}
                  />
                )}

                <FormSubmitLoader open={this.state.isSubmitting} />

                <SubmitDialogs 
                  showSubmissionDialog={this.state.showSubmissionDialog}
                  closeSubmissionDialog={() => this.setState({ showSubmissionDialog: false })}
                  formState={{...this.state}}
                />

                <Dialog open={this.state.pagesErrorDialog}>
                  <DialogTitle>Required questions must be answered</DialogTitle>

                  <DialogContent>
                    <p>
                      The following questions are required and must be answered
                      before this form can be submitted:
                    </p>
                    <List>
                      {this.state.pagesError.map((aPageError, index) => {
                        return (
                          <ListItem
                            style={{ paddingBottom: "4px", paddingTop: "4px" }}
                          >
                            "{aPageError.questionTitle}" on "
                            {aPageError.pageTitle}"
                          </ListItem>
                        );
                      })}
                    </List>
                  </DialogContent>

                  <DialogAction>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        this.setState({ pagesErrorDialog: false });
                      }}
                    >
                      Close
                    </Button>
                  </DialogAction>
                </Dialog>
              </>
            );
          } else {
            return <FormSkeletonLoader classes={classes} />;
          }
        }
      } catch (e) {
        console.log(e);
        return <FormSkeletonLoader classes={classes} />;
      }
    }
  }
  
  const mapStateToProps = (state, props) => {
    return {
      form: state.form,
      loading: state.form.loading
    };
  };
  
  const mapDispatchToProps = {
    dispatchfetchNextPage: (
      password,
      form_id,
      index,
      projectVersion,
      projectSessionId,
      threadId
    ) =>
      fetchNextPageData({
        password,
        form_id,
        index,
        projectVersion,
        projectSessionId,
        threadId
      }),
    dispatchgoBackToPrevious: () => goBackToPrevious(),
    dispatchaddPageData: (key, answer, question_text, format) =>
      addPageData({ key, answer, question_text, format }),
    dispatchaddPageDataCalendar: (
      key,
      answer,
      question_text,
      format,
      rawValue,
      outputFormat,
      timezone
    ) =>
      addPageDataCalendar({
        key,
        answer,
        question_text,
        format,
        rawValue,
        outputFormat,
        timezone
      }),
    dispatchaddPageDataTable: (
      key,
      answer,
      question_text,
      format,
      exportFormat,
      tableAnswerKeys,
      colNames
    ) =>
      addPageDataTable({
        key,
        answer,
        question_text,
        format,
        exportFormat,
        tableAnswerKeys,
        colNames
      }),
    dispatchUnmountForm: () => unmountForm(),
    dispatchgoBackToANY: (index) => goBackToANY(index),
    dispatchFormLoading: () => formLoading(),
    dispatchFetchFormComments: (session_id, isTest = false) =>
      fetchFormComments(session_id, isTest),
    dispatchUpdateFormState: (
      formSession,
      formMeta
      ) =>
        updateFormState({
          formSession,
          formMeta 
        }),
  };
  
  Form.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Form));