import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { getValidationHelperText, validatePattern } from '../../utils/inputValidation';

const styles = theme => ({
    annotationNumberInput: {
        border: "1px solid #d5d5d5",
        paddingBottom: "5px",
        paddingLeft: "24px",
        paddingRight: "12px",
        borderRadius: "4px",
        fontSize: "14px",
        marginTop: "5px",
        overflow: "hidden",
        paddingTop: "2px",
        width: 272,
        wordBreak: "break-word",
        minHeight: "30px"
    },
    textroot: {
        width: 272,
        margin: 'auto'
    },
    input: {
        fontSize: 14
    },
    root: {
        '& label.Mui-focused': {},

        '& .MuiOutlinedInput-root': {
            '& input': {
                paddingTop: 10,
                paddingBottom: 8,
                paddingLeft: 24,
            },
            '& fieldset': {},
            '&:hover fieldset': {
                borderColor: '#2196F3'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#2196F3'
            },
        },
    },

});


class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.textFieldRef = React.createRef()
        this.state = {
            answer: ''
        };

    }

    componentDidMount() {
        const handleWheel = e => e.preventDefault();
        if(this.textFieldRef.current) this.textFieldRef.current.addEventListener("wheel", handleWheel);


        let answer_data = this.props.form.answer_data[this.props.data.key];
        if (answer_data && answer_data.answer) {
            this.setState({ answer: this.props.form.answer_data[this.props.data.key].answer[0] })
        } else {
            if (this.props.data.data.toString() && this.state.answer == '') {
               
                this.props.getDataFromComponent({ 'number_answer': this.props.data.data.toString() }, this.props.data.key, this.props.data.question_text, this.props.data.data)
                this.setState({ answer: this.props.data.data })
            }
            if ((this.props.data.auto.toString()) && !this.props.form.answer_data[this.props.data.key]) {
               
                this.props.getDataFromComponent({ 'number_answer': this.props.data.auto.toString() }, this.props.data.key, this.props.data.question_text, this.props.data.data)
                this.setState({ answer: this.props.data.auto })

            }

        }


    }

    componentWillUnmount() {
        const handleWheel = e => e.preventDefault();
        if (this.textFieldRef.current) this.textFieldRef.current.removeEventListener("wheel", handleWheel);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
        if (this.props.data.data != prevProps.data.data) {
            this.props.getDataFromComponent({ 'number_answer': this.props.data.data.toString() }, this.props.data.key, this.props.data.question_text, this.props.data.data)
            this.setState({ answer: this.props.data.data })
        }
    }

    handleOnChange = (event) => {
        this.setState({ answer: event.target.value })
    }

    handleOnBlur = (event) => {
        let text = { 'number_answer': event.target.value }
        this.props.getDataFromComponent(text, this.props.data.key, this.props.data.question_text, this.props.data.data)
    };

    render() {

        const { classes, data } = this.props;

        try {
            return (
                <div onScroll={event => {
                    event.preventDefault();
                }}
                    onWheel={event => {
                        event.preventDefault();
                    }} className={classes.root}>

                    {!this.props.isAnnotationModeOn ? <TextField
                        onScroll={event => event.currentTarget.blur()}
                        onWheel={event => event.currentTarget.blur()}
                        value={parseFloat(this.state.answer)}
                        id='free-text-input'
                        className={classes.textroot}
                        InputProps={{
                            className: classes.input,
                            inputProps: this.props.data.style
                        }}
                        ref={this.textFieldRef}
                        step="any"
                        disabled={this.props.data.read_only}
                        defaultValue={this.props.data.auto == "" ? parseFloat(this.props.data.data) : parseFloat(this.props.data.auto)}
                        type='number'
                        placeholder={this.props.data.data.placeholder}
                        variant="outlined"
                        label={this.props.data.data.label}
                        onChange={this.props.data.read_only == 'true' ? '' : this.handleOnChange}
                        onBlur={this.props.data.read_only == 'true' ? '' : this.handleOnBlur}
                        error={!validatePattern(this.state.answer, this.props.data.validation, 'number')}
                        helperText={getValidationHelperText(this.state.answer, this.props.data.validation, 'number')}
                    /> : <div id={`${data.key}-answer-text`} className={classes.annotationNumberInput}>
                            {this.state.answer}
                        </div>}
                </div>
            );
        } catch (e) {
            return (<h5>Error encountered</h5>)
        }

    }
}


const mapStateToProps = (state, props) => {
    return {
        form: state.form
    };
};

const mapDispatchToProps = {};


NumberInput.propTypes = {
    classes: PropTypes.object.isRequired,

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(NumberInput));
